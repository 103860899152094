import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  Card,
  CardBody
} from "reactstrap";
import { setSecondaryEmailAction } from "slices/onboarding/thunk";

const SecondaryEmailSection = (
  {
    secondaryEmail,
    isSecondaryEmailVerified
  } : {
    secondaryEmail: string;
    isSecondaryEmailVerified: boolean;
  }
) => {
  const [email, setEmail] = useState(secondaryEmail || "");
  const [verificationSent, setVerificationSent] = useState(false);
  const [isVerified, setIsVerified] = useState(isSecondaryEmailVerified);
  const [error, setError] = useState("");
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setEmail(secondaryEmail || "");
  }, [secondaryEmail]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setVerificationSent(false);
    setIsVerified(false);
    setError("");
  };

  const sendVerificationEmail = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
      }

      dispatch(setSecondaryEmailAction(email));
      setError("");
      setVerificationSent(true);
      setIsVerified(false);
    } catch (err) {
      setError("Failed to send verification email. Please try again.");
    }
  };

  return (
    <Card className="mt-4">
      <CardBody>
      <div className="d-flex align-items-center mb-2">
          <h5 className="card-title mb-0 me-2">Secondary Email</h5>
          {isVerified && (
            <small className="text-success fw-medium">
                  Verified
            </small>
          )}
        </div>
        
        {error && <Alert color="danger">{error}</Alert>}
        
        <Form>
          <FormGroup>
            <InputGroup>
            <Input
              type="email"
              id="secondaryEmail"
              placeholder="Enter your secondary email"
              value={email}
              onChange={handleEmailChange}
              disabled={isVerified}
              className={isVerified ? "bg-white text-dark" : ""}
              style={isVerified ? { opacity: 1 } : {}}
            />
            </InputGroup>
            {verificationSent && !isVerified && (
              <small className="text-muted mt-1 d-block">
                Verification email sent. Please check your inbox.
              </small>
            )}
          </FormGroup>
          
          <div className="d-flex mt-1">
            {!isVerified && !verificationSent && (
              <Button 
                color="primary" 
                size="sm"
                onClick={sendVerificationEmail}
                disabled={!email}
              >
                <i className="fas fa-envelope me-1"></i>
                Send Verification Email
              </Button>
            )}

            {verificationSent && !isVerified && (
              <Button 
                color="outline-secondary"
                size="sm"
                onClick={sendVerificationEmail}
              >
                <i className="fas fa-redo me-1"></i>
                Resend Verification Email
              </Button>
            )}

            {isVerified && (
              <Button 
                color="outline-danger" 
                size="sm"
                onClick={() => {
                  setEmail("");
                  setIsVerified(false);
                }}
              >
                <i className="fas fa-trash-alt me-1"></i>
                Remove Secondary Email
              </Button>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default SecondaryEmailSection;