import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useCallback, useEffect, useRef, useState } from "react";
import avatar from "../../assets/images/users/user_profile.svg";
import { acceptRescheduleRequest, updateSessionRequest, saveSessionNoteRequest, fetchSessionNotes, deleteSessionNoteRequest } from "slices/session/thunk";
import { useDispatch} from "react-redux";
import { useAppSelector } from "slices";
import ChatIcon from "Components/Common/ChatIcon";

dayjs.extend(utc);
dayjs.extend(timezone);

const SessionList = ({
  listData,
  openModal,
  type,
  handleRescheduleModalOpen,
  handleDeclineRequest,
  contactId,
}) => {
  const dispatch = useDispatch<any>();
  const [collapsedStates, setCollapsedStates] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [acceptRequestId, setAcceptRequestId] = useState(null);
  const [acceptRecheduleRequestId, setAcceptRecheduleRequestId] = useState(null);
  const { user: currentUser } = useAppSelector((state) => state.Global);
  const [sessionIdToShowQuestions, setSessionIdToShowQuestions] = useState(null);
  const [notesCollapsedStates, setNotesCollapsedStates] = useState({});
  const [noteText, setNoteText] = useState({});
  const [savingNoteId, setSavingNoteId] = useState(null);
  const [deletingNoteId, setDeletingNoteId] = useState(null)
  const sessionNotes = useAppSelector(state => state.SessionList.sessionNotes);
  const notesContainerRefs = useRef({});

  useEffect(() => {
    if (listData && listData.length > 0) {
      listData.forEach(item => {
        dispatch(fetchSessionNotes(item.id));
      });
    }
  }, [dispatch, listData]);

  const toggleNotesCollapse = (id: string) => {
    setNotesCollapsedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleNoteChange = (id: string, value: string) => {
    setNoteText((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const scrollToBottom = (sessionId) => {
    if (notesContainerRefs.current[sessionId]) {
      const container = notesContainerRefs.current[sessionId];
      container.scrollTop = container.scrollHeight;
    }
  };
  
  const saveNote = async (sessionId) => {
    if (!noteText[sessionId]?.trim()) return;
    
    setSavingNoteId(sessionId);
    
    await dispatch(saveSessionNoteRequest({
      sessionId,
      content: noteText[sessionId]
    }));

    setNoteText(prev => ({
      ...prev,
      [sessionId]: ""
    }));
    
    setSavingNoteId(null);

    setTimeout(() => scrollToBottom(sessionId), 100);
  };

  const deleteNote = async (sessionId, noteId) => {
      setDeletingNoteId(noteId)

      await dispatch(deleteSessionNoteRequest({ sessionId, noteId }));

      setSavingNoteId(null);
};

  const toggleConfirmationModal = useCallback(() => {
    setConfirmationModal(!confirmationModal);
  }, [confirmationModal]);

  const toggleCollapse = (id: any) => {
    setCollapsedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleAcceptRequest = () => {
    dispatch(
      updateSessionRequest({ requestId: acceptRequestId, status: "accept" })
    );
    setConfirmationModal(false);
  };

  const acceptRequest = (id: any) => {
    setAcceptRequestId(id);
    setConfirmationModal(true);
  };

  const handleRecheduledAccept = () => {
    dispatch(acceptRescheduleRequest({ requestId: acceptRecheduleRequestId, contactId }));
    setConfirmationModal(false);
  };

  const acceptRecheduled = (id: any) => {
    setAcceptRecheduleRequestId(id);
    setConfirmationModal(true);
  }

  const renderButtonsAsPerType = (
    isMentor: boolean,
    id: any,
    userId: any,
    canAcceptRequest: any,
    canReschedule: any,
    isFeedbackCompleted: boolean,
    meetingLink: string,
    reschedulePendingOnMe: boolean,
    sessionQuestions: string
  ) => {
    if (type === "REQUESTED") {
      return (
        <>
          {canAcceptRequest && (
            <Button
              className="btn btn-sm btn-success py-1"
              onClick={() => acceptRequest(id)}
            >
              Accept
            </Button>
          )}
          <Button
            className="btn btn-sm btn-soft-dark py-1"
            onClick={() => handleRescheduleModalOpen(id, userId, isMentor)}
            disabled={!canReschedule}
          >
            Reschedule
          </Button>
          {!isMentor ? (
            <Button
              className="btn btn-sm btn-link py-1 text-danger px-0"
              color="red"
              onClick={() => handleDeclineRequest(id, "DECLINE")}
            >
              Cancel
            </Button>
          ) : (
            <Button
              className="btn btn-sm btn-link py-1 text-danger px-0"
              color="red"
              onClick={() => handleDeclineRequest(id, type)}
            >
              Decline
            </Button>
          )}
        </>
      );
    } else if (type === "COMPLETED") {
      if (isFeedbackCompleted)
        return <span className="pb-4" />;
      return (
        <Link to={`/sessions/${id}/feedback`}>
          <Button className="btn btn-sm btn-primary py-1">Give Feedback</Button>
        </Link>
      );
    } else if (type === "SCHEDULED") {
      return (
        <>
          <Button
            className="btn btn-sm btn-primary py-1"
            style={{ display: meetingLink ? "block" : "none" }}
            onClick={() => window.open(meetingLink, "_blank")}
          >
            Join Session
          </Button>
          {reschedulePendingOnMe && (
            <Button
              className="btn btn-sm btn-success py-1"
              onClick={() => acceptRecheduled(id)}
            >
              Accept
            </Button>

          )}
          <Button
            className="btn btn-sm btn-soft-dark py-1"
            onClick={() => handleRescheduleModalOpen(id, userId, isMentor)}
            disabled={!canReschedule}
          >
            Reschedule
          </Button>
          <Button
            className="btn btn-sm btn-link py-1 text-danger px-0"
            color="red"
            onClick={() => handleDeclineRequest(id, type)}
          >
            Cancel
          </Button>

          <Modal
            isOpen={sessionIdToShowQuestions === id}
            toggle={() => setSessionIdToShowQuestions(null)}
            centered={true}
            size="lg"
          >
            <ModalHeader>
              Below are the some questions that you can ask during session to
              make it more interactive and engaging.
            </ModalHeader>
            <ModalBody>
              <div>
                {sessionQuestions &&
                  sessionQuestions?.split("\n")?.map((question, index) => (
                    <div key={index} style={{ fontSize: "14px" }}>
                      <p style={{ marginBottom: 0 }}>{question}</p>
                      <br />
                    </div>
                  ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                onClick={() => setSessionIdToShowQuestions(null)}
                className="btn btn-secondary"
              >
                Close
              </button>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  };

  const renderRescheduleHistory = (rescheduleHistory: any) => {
    if (rescheduleHistory && rescheduleHistory.reason) {
      return (
        <div className="mt-3 font-size-12">
          <CardText className="p-0 m-0">Reschedule Reason:</CardText>
          <span className="fw-bold font-size-11">
            {rescheduleHistory.reason}
          </span>
          {rescheduleHistory.status === "PENDING" && (
            <div>
              <Button
                className="btn btn-sm btn-success mt-2"
                onClick={() => handleRecheduledAccept()}
              >
                Accept
              </Button>
              <Button
                className="btn btn-sm btn-link text-danger px-0"
                onClick={() => handleDeclineRequest(rescheduleHistory.id, "CANCEL")}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      );
    }
  };

  const renderNotes = (notes: any) => {
    if (notes) {
      return (
        <div className="mt-3 font-size-12">
          <CardText className="p-0 m-0">Notes From Mentee:</CardText>
          <span className="fw-bold font-size-11">{notes}</span>
        </div>
      );
    }
  };

  const renderSkills = (skills: any) => {
    if (skills && skills.length > 0) {
      return (
        <div className="mt-3 font-size-12">
          <CardText className="p-0 m-0">Skills:</CardText>
          <span className="fw-bold font-size-11">
            {skills.map((skill: any, key: number) => (
              <span
                key={key}
                className="badge bg-light me-1 font-size-10 text-capitalize"
              >
                {skill.split("-").join(" ")}
              </span>
            ))}
          </span>
        </div>
      );
    }
  };

  const renderFeedback = (item: any) => {
    const userId = item.user.id;
    const feedbacks = item?.feedbacks;
    if (item.status !== "COMPLETED" || !feedbacks) return;

    const feedbackIndex = feedbacks.findIndex(
      (rating: any) => rating.participantId === userId
    );

    if (feedbacks && feedbackIndex >= 0) {
      const feedback = feedbacks[feedbackIndex]?.feedback;
      return (
        <div className="mt-3 font-size-12">
          <CardText className="p-0 m-0">Feedback:</CardText>
          <span className="font-size-11">
            <span key={feedback.id}>
              {Object.keys(feedback).map((key) => {
                const feedbackValue = feedback[key];
                return (
                  <div key={key}>
                    <strong>
                      {" "}
                      {key
                        .split("-")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                      :{" "}
                    </strong>
                    {typeof feedbackValue === "number" ? (
                      Array(feedbackValue).fill(null).map((_, i) => (
                        <i key={i} className="bx bxs-star" style={{ color: "gold" }} />
                        ))
                    ) : (
                      <span>{feedbackValue}</span>
                    )}
                  </div>
                );
              })}
            </span>
          </span>
        </div>
      );
    } else {
      return (
        <div className="mt-3 font-size-12">
          <CardText className="p-0 m-0">Feedback:</CardText>
          <span className="fw-bold font-size-11">No feedback given</span>
        </div>
      );
    }
  };

  const renderRejectionReason = (item: any) => {
    const { rejectionReason, status } = item;

    if (rejectionReason && (status === "CANCELED" || status === "REJECTED")) {
      return (
        <div className="mt-3 font-size-12">
          <CardText className="p-0 m-0">Reason:</CardText>
          <span className="fw-bold font-size-11">{rejectionReason}</span>
        </div>
      );
    }
  };

  const renderConfirmationModal = () => (
    <Modal isOpen={confirmationModal} toggle={toggleConfirmationModal} centered>
      <ModalHeader toggle={toggleConfirmationModal} tag="h4">
        Accept User Request
      </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to accept this request?</p>
        <div className="text-right d-flex justify-content-end">
          <button
            type="button"
            onClick={() => {
              acceptRecheduleRequestId ? handleRecheduledAccept() : handleAcceptRequest();
            }}
            className="btn btn-primary me-2"
          >
            Accept
          </button>
          <button
            type="button"
            onClick={toggleConfirmationModal}
            className="btn btn-secondary"
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );

  return (
    <>
      <Row>
        {(listData || []).map((item: any, key: number) => {
          const user = item.user;
          const rescheduleHistory = item.rescheduleHistory;
          const scheduledAt = rescheduleHistory
            ? rescheduleHistory.newTime
            : item.scheduledAt;
          const canAcceptRequest = rescheduleHistory
            ? item.reschedulePendingOnMe
            : item.isMentor;
          const canReschedule = rescheduleHistory
            ? item.reschedulePendingOnMe
            : true;
          const isFeedbackCompleted =
            item?.feedbacks &&
            item.feedbacks.length > 0 &&
            item.feedbacks.some(
              (feedback: any) => feedback.participantId === currentUser.id
            );
          const sessionNotesList = sessionNotes[item.id] || [];


          return (
            <Col xl={6} key={key}>
              <Card outline className="border">
              <CardHeader className="mb-0 bg-transparent d-flex justify-content-between align-items-center px-3">
                <div className="my-0">
                  <p className="mb-1">
                    {type === "CANCELED" ? (
                      <span className="text-black">Canceled session with </span>
                    ) : (
                      <span className="text-black">
                        {rescheduleHistory
                          ? "Rescheduled mentorship"
                          : "Mentorship"}{" "}
                        session with{" "}
                      </span>
                    )}
                    <Link
                      to={`/profile/${user.id}?${item.isMentor ? "viewingAs=MENTOR" : "viewingAs=MENTEE"
                      }`}
                      className="text-primary"
                    >
                      {user.firstName} {user.lastName}
                    </Link>
                    <span
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => setSessionIdToShowQuestions(item.id)}
                    >
                      <i
                        id="seesionCuesToolTip"
                        style={{
                          padding: "5px",
                          display: item.sessionQuestions && type === "SCHEDULED"
                              ? "inline-block"
                              : "none",
                        }}
                        className="fas fa-lightbulb font-size-18 text-warning"
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="seesionCuesToolTip"
                      >
                        Session Cues
                      </UncontrolledTooltip>
                    </span>
                  </p>
                  <div className="d-flex align-items-center">
                    <span className="badge bg-light font-size-12 me-2">
                      {item.isMentor ? "As Mentor" : "As Mentee"}
                    </span>
                    <ChatIcon user={user} />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  {(type === "SCHEDULED" || type === "COMPLETED") && (
                    <Button
                      className="btn btn-light waves-effect p-1 px-2 bg-transparent"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleNotesCollapse(item.id);
                      }}
                    >
                      Notes
                      {notesCollapsedStates[item.id] ? (
                        <i className="bx bx-chevron-up me-0 ms-1" />
                      ) : (
                        <i className="bx bx-chevron-down me-0 ms-1" />
                      )}
                    </Button>
                  )}
                  <Button
                    className="btn btn-light waves-effect p-1 px-2 bg-transparent"
                    onClick={() => toggleCollapse(item.id)}
                  >
                    Details
                    {collapsedStates[item.id] ? (
                      <i className="bx bx-chevron-up me-0 ms-1" />
                    ) : (
                      <i className="bx bx-chevron-down me-0 ms-1" />
                    )}
                  </Button>
                </div>
              </CardHeader>
                <CardBody className="m-0 py-0 px-3">
                  <div className="d-flex gap-3 align-items-center my-2">
                    <div className="d-flex align-items-center">
                      <i className="bx bx-calendar font-size-16" />
                      <span className="ms-1">
                        {dayjs.utc(scheduledAt).tz(currentUser.timezone).format("DD MMMM, YYYY")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-time font-size-16" />
                      <span className="ms-1">
                        {dayjs.utc(scheduledAt).tz(currentUser.timezone).format("h:mm A")}
                      </span>
                    </div>
                  </div>
                  <Collapse isOpen={notesCollapsedStates[item.id]} className="border-top">
                    <div className="mt-3">
                      <div className="notes-container mb-3" 
                      style={{ maxHeight: '250px', overflowY: 'auto', padding: '8px' }}
                      ref={(el) => {
                        notesContainerRefs.current[item.id] = el;
                      }}
                      >
                        {sessionNotesList && sessionNotesList.length > 0 ? (
                          sessionNotesList.map((note, index) => (
                            <div 
                              key={note.id} 
                              className="note-item p-3 mb-2 rounded d-flex justify-content-between align-items-start shadow-sm" 
                              style={{ backgroundColor: '#f8f9fa', border: '1px solid #e9ecef' }}
                            >
                              <div className="w-100">
                                <div className="note-content font-size-12" style={{ color: '#495057', whiteSpace: 'pre-line' }}>{note.content}</div>
                                <div className="note-timestamp font-size-10 text-muted mt-1" style={{ fontSize: '10px' }}>
                                  {dayjs(note.createdAt).format("MMM D, YYYY - h:mm A")}
                                </div>
                              </div>
                              <Button
                                color="secondary"
                                size="sm"
                                className="ms-2 px-2 py-1"
                                style={{ minWidth: '34px', height: '34px' }}
                                onClick={() => deleteNote(item.id, note.id)}
                                disabled={deletingNoteId === note.id}
                              >
                                {deletingNoteId === note.id ? (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                                ) : (
                                  <i className="bx bx-trash font-size-16 align-middle"></i>
                                )}
                              </Button>
                            </div>
                          ))
                        ) : (
                          <div className="text-muted font-size-12 p-3 text-center" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px' }}>No notes yet</div>
                        )}
                      </div>

                      <div className="d-flex flex-column">
                        <textarea
                          className="form-control form-control-sm mb-2"
                          placeholder="Add a note..."
                          value={noteText[item.id] || ""}
                          onChange={(e) => handleNoteChange(item.id, e.target.value)}
                          style={{ border: '1px solid #ced4da', minHeight: '60px', resize: 'vertical' }}
                          rows={3}
                        />
                        <div className="d-flex justify-content-end">
                          <Button
                            color="primary"
                            size="sm"
                            style={{ minWidth: '70px' }}
                            onClick={() => saveNote(item.id)}
                            disabled={savingNoteId === item.id || !noteText[item.id]?.trim()}
                          >
                            {savingNoteId === item.id ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse
                    isOpen={collapsedStates[item.id]}
                    className="border-top"
                  >
                    <div className="mt-3">
                      <div>
                        <CardText className="font-size-11 mb-2">
                          {item.isMentor
                            ? "Requested By (Mentee):"
                            : "Requested to (Mentor):"}
                        </CardText>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src={user?.avatarUrl || avatar}
                              alt="avatar"
                              className="avatar-sm rounded-circle"
                            />
                            <div className="d-flex flex-column ms-2 font-size-11">
                              <span>
                                {user.firstName} {user.lastName}
                              </span>
                              <span className="text-dark">{user.email}</span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 font-size-12">
                          <CardText className="p-0 m-0">
                            {item.isMentor
                              ? "Mentee Timezone: "
                              : "Mentor Timezone: "}
                          </CardText>
                          <span className="fw-bold font-size-11">
                            {user.timezone}
                          </span>
                        </div>
                        <div className="mt-3 font-size-12">
                          <CardText className="p-0 m-0">Meeting type:</CardText>
                          <span className="fw-bold font-size-11">
                            {item.mode?.split("_")?.join(" ") || "IN PERSON"}
                          </span>
                        </div>
                        {renderNotes(item.notes)}
                        {renderRescheduleHistory(rescheduleHistory)}
                        {renderSkills(item.skills)}
                        {renderFeedback(item)}
                        {renderRejectionReason(item)}
                      </div>
                    </div>
                  </Collapse>
                  <div className="d-flex gap-3 my-3">
                    {renderButtonsAsPerType(
                      item.isMentor,
                      item.id,
                      user.id,
                      canAcceptRequest,
                      canReschedule,
                      isFeedbackCompleted,
                      item.meetingLink,
                      item.reschedulePendingOnMe,
                      item.sessionQuestions
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}

        {renderConfirmationModal()}
      </Row>
    </>
  );
};
export default SessionList;
