import { createSlice } from "@reduxjs/toolkit";
import { fetchReport } from "./thunk";
export interface ReportState {
  reportType: string;
  fromDate: string;
  toDate: string;
  reportData: any[];
  columns: string[];
  loading: boolean;
  error: string | null;
  total: 0,
  perPage: 10,
  page: 1,
  totalPages: 0,

}

const initialState: ReportState = {
  reportType: "",
  fromDate: "",
  toDate: "",
  reportData: [],
  columns: [],
  loading: false,
  error: null,
  total: 0,
    perPage: 10,
    page: 1,
    totalPages: 0,
};

const processApiColumns = (apiColumns: any[]): any[] => {
  return apiColumns.map(col => ({
    ...col,
    header: col.title || col.accessorKey, // Use title if available, fallback to accessorKey
  }));
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setFromDate: (state, action) => {
      state.fromDate = action.payload;
    },
    setToDate: (state, action) => {
      state.toDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReport.pending, (state) => { 
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchReport.fulfilled, (state, action) => {
      state.reportData = action.payload.reportData;
      state.page = action.payload.page;
      state.perPage = action.payload.perPage;
      state.totalPages = action.payload.totalPages;
      state.columns = processApiColumns(action.payload.columns);
      state.total = action.payload.total;
      state.loading = false;
      // console.log("Report Data", action.payload);
    });
    builder.addCase(fetchReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch report data";
    });
  },
});

export default reportSlice.reducer;
