import React, { useCallback, useEffect, useMemo, useState } from "react";
import LineChart from "Components/charts/ApexLineChart";
import withRouter from "Components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

//Import mini card widgets
import MiniCards from "./mini-card";

import WelcomeCard from "./Components/WelcomeCard";

import { useAppDispatch, useAppSelector } from "slices";
import TableContainer from "Components/Common/TableContainer";
import {
  getAnalyticsCount,
  getMenteeAvgScore,
  getMentorAvgScore,
  getSessionByDay,
  getSessionByDuration,
  getUserAnalytics,
} from "slices/analytics/thunk";
import Demographics from "./Components/Demographics";
import Paginations from "Components/Common/Pagination";
import Select from "react-select";
import { fetchOrgMetaData } from "slices/onboarding/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import OrgSessions from "./Components/OrgSessions";


const AdminDashboard = () => {

  //meta title
  document.title = "Dashboard | Mentorgain";

  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const userTypesData: any[] = [
    { label: "Mentor", value: "mentor" },
    { label: "Mentee", value: "mentee" },
    { label: "Both", value: "both" },
  ];
  const orderByData: any[] = [
    { label: "Type", value: "type" },
    { label: "Department", value: "department" },
    { label: "Number of sessions", value: "numMentorshipSessions" },
    { label: "Satisfaction Score", value: "satisfactionScore" },
  ];
  const orderData: any[] = [
    { label: "ASC", value: "asc" },
    { label: "DESC", value: "desc" },
  ];
  const [userTypes, setUserTypes] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<any>();
  const [order, setOrder] = useState<any>();

  const {
    analyticsCount,
    totalSessions,
    userAnalytics,
    menteeAvgScore,
    mentorAvgScore,
  } = useAppSelector((state) => state.Analytics);

  const miniCards = [
    {
      title: "Only Mentees",
      iconClass: "bx-check-circle",
      text: analyticsCount?.mentees,
    },
    {
      title: "Only Mentors",
      iconClass: "bx-hourglass",
      text: analyticsCount?.mentors,
    },
    {
      title: "Both",
      iconClass: "bx-package",
      text: analyticsCount?.both,
    },
    {
      title: "Total Sessions",
      iconClass: "bx-package",
      text: analyticsCount?.totalSessions,
    },
    {
      title: "Mentee Average Score",
      iconClass: "bx-package",
      text: menteeAvgScore,
    },
    {
      title: "Mentor Average Score",
      iconClass: "bx-package",
      text: mentorAvgScore,
    },
  ];

  useEffect(() => {
    dispatch(getAnalyticsCount());
    dispatch(getSessionByDuration());
    dispatch(getSessionByDay());
    dispatch(getMenteeAvgScore());
    dispatch(getMentorAvgScore());
    dispatch(fetchOrgMetaData());
  }, [dispatch]);

  const handleFilterData = useCallback(() => {
    const filterQuery: any = {};

    if (userTypes.length) {
      filterQuery["filter.userTypes"] = userTypes.map(
        (userType) => userType.value
      );
    }

    if (departments.length) {
      filterQuery["filter.departments"] = departments.map(
        (department) => department.value
      );
    }

    if (orderBy) {
      filterQuery["filter.orderBy"] = orderBy["value"];
    }

    if (order) {
      filterQuery["filter.order"] = order["value"];
    }

    dispatch(
      getUserAnalytics({
        filterQuery,
        perPage: perPage,
        page: currentPage,
      })
    );
  }, [dispatch, currentPage, userTypes, departments, orderBy, order, perPage]);

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData]);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Department",
        accessorKey: "department",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Number of sessions",
        accessorKey: "numMentorshipSessions",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Satisfaction Score",
        accessorKey: "satisfactionScore",
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  const selectOrgMetaData = createSelector(
    (state: any) => state.Onboarding,
    (onboarding) => ({
      orgDepartments: onboarding.departments,
    })
  );
  const { orgDepartments } = useSelector(selectOrgMetaData);
  const departmentsData = orgDepartments.map((department) => ({
    label: department.title,
    value: department.value,
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
          <Col xl={4} className="d-flex flex-column">
            <WelcomeCard />
            <Demographics />
          </Col>

          <Col xl={8}>
            <Row className="g-3">
              {(miniCards || []).map((card: any, key: number) => (
                <MiniCards
                  title={card.title}
                  text={card.text}
                  iconClass={card.iconClass}
                  key={"_card_" + key}
                />
              ))}
            </Row>

            <Card className="mt-3">
              <CardBody>
                <div id="revenue-chart">
                  <LineChart
                    series={[
                      {
                        name: "Mentorship Session",
                        data: Object.values(totalSessions as any) as number[],
                      },
                    ]}
                    categories={Object.keys(totalSessions as any) as string[]}
                    titleText={"Mentorship Session Statistics"}
                    yAxisLabel={"Session Count"}
                    dataColors='["--bs-success","--bs-primary"]'
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* Full Width User Activity Statistics */}
          <Col xs={12}>
            <Card className="mt-3">
              <CardBody>
                <CardTitle className="mb-4">User Activity Statistics</CardTitle>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="col-6 col-md-3 mb-2">
                    <Select
                      name="Type"
                      placeholder="User Type"
                      isClearable
                      isMulti
                      options={userTypesData}
                      onChange={(selectedOptions) => {
                        selectedOptions === null
                          ? setUserTypes([])
                          : setUserTypes(selectedOptions);
                        setCurrentPage(1);
                      }}
                      value={userTypes}
                      menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                    />
                  </div>

                  <div className="col-6 col-md-3 mb-2">
                    <Select
                      name="Department"
                      placeholder="Department"
                      isClearable
                      isMulti
                      options={departmentsData}
                      onChange={(selectedOptions) => {
                        selectedOptions === null
                          ? setDepartments([])
                          : setDepartments(selectedOptions);
                        setCurrentPage(1);
                      }}
                      value={departments}
                      menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                    />
                  </div>

                  <div className="col-6 col-md-3 mb-2">
                    <Select
                      name="Order By"
                      placeholder="Order By"
                      isClearable
                      options={orderByData}
                      onChange={(selectedOption) => {
                        selectedOption === null
                          ? setOrderBy("")
                          : setOrderBy(selectedOption);
                        setCurrentPage(1);
                      }}
                      value={orderBy}
                      menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                    />
                  </div>

                  <div className="col-6 col-md-2 mb-2">
                    <Select
                      name="Order"
                      placeholder="Order"
                      isClearable
                      options={orderData}
                      onChange={(selectedOption) => {
                          selectedOption === null
                            ? setOrder("")
                            : setOrder(selectedOption);
                        setCurrentPage(1);
                      }}
                      value={order}
                      menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                    />
                  </div>
                </div>

                <TableContainer
                  columns={columns}
                  data={userAnalytics?.usersData || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  currentPage={currentPage}
                  totalPages={userAnalytics?.totalPages}
                  setCurrentPage={setCurrentPage}
                />

                <Paginations
                  perPageData={perPage}
                  setPerPage={setPerPage}
                  totalDataCount={userAnalytics?.total}
                  totalPages={userAnalytics?.totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isShowingPageLength={true}
                  paginationClass="pagination pagination-rounded"
                />
              </CardBody>
            </Card>
            <OrgSessions/>
          </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminDashboard);
