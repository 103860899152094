import React from "react";
import ReactApexChart from "react-apexcharts";

interface SeriesData {
  name: string;
  data: { x: string; y: number }[];
}

const SkillTrendLineChart = ({ data }: { data: any }) => {
 
  if (!data || typeof data !== "object") {
    console.error("Invalid data format:", data);
    return null;
  }

  const categories = Object.keys(data); // ✅ Ensure all months are included

  let skills: Record<string, { x: string; y: number }[]> = {};
  
  // Step 1: Collect all skills across all months
  const allSkills = new Set<string>();
  for (const month in data) {
    if (data[month] && typeof data[month] === "object") {
      for (const skill in data[month]) {
        allSkills.add(skill);
      }
    }
  }

  // Step 2: Initialize every skill with all months (set y: 0 if no data)
  for (const skill of allSkills) {
    skills[skill] = categories.map((month) => ({
      x: month,
      y: data[month]?.[skill] ?? 0, // ✅ Fill missing months with `y: 0`
    }));
  }

  // Step 3: Convert skills to ApexCharts series format
  const series: SeriesData[] = Object.keys(skills).map((skill) => ({
    name: skill,
    data: skills[skill],
  }));

  
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      height: 600,
      zoom: { enabled: false },
    },
    title: {
      text: "Skill Trend Analysis",
      align: "left",
    },
    xaxis: {
      type: "category",
      categories: categories,
      title: { text: "Months" },
    },
    yaxis: {
      title: { text: "Session Count" },
    },
    markers: { size: 4 },
    stroke: { width: 3, curve: "smooth" },
  };

  return <ReactApexChart options={options} type="line" series={series} height={600} />;
};

export default SkillTrendLineChart;
