import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getOrgSessions } from "../../../slices/admin/thunk";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import Paginations from "Components/Common/Pagination"
import Select from "react-select";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import OrgSessionsTable from "Components/OrgSessionsTable";
import FeedbackCell from "Components/FeedbackCell";
import SkillsCell from "Components/SkillsCell";

const api = new APIClient();

const OrgSessions = () => {
  document.title = "Org Sessions | Mentorgain";

  const selectProperties = createSelector(
    (state: any) => state.Admin,
    (admin) => ({
      orgSessions: admin.orgSessions,
      loading: admin.loading,
    })
  );
  const { orgSessions, loading }: any = useSelector(selectProperties);
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [status, setStatus] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<any>();
  const [skills, setSkills] = useState<any>([]);
  const [allSkills, setAllSkills] = useState<any>();
  const [mentorName, setMentorname] = useState<any>();
  const [menteeName, setMenteename] = useState<any>();
  

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const dispatch = useDispatch<any>();

  const statusData: any[] = [
    { label: "Scheduled", value: "scheduled" },
    { label: "Completed", value: "completed" },
    { label: "Canceled", value: "canceled" },
  ];

  const orderByData: any[] = [
    { label: "Mentor Name", value: "mentorName" },
    { label: "Mentee Name", value: "menteeName" },
    { label: "Status", value: "status" },
    { label: "Scheduled At", value: "scheduledAt" },
  ];

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <div style={{ textAlign: "center", padding: "12px" }}>
              {!cellProps.row.original.img && (
                <div style={{ height: "1.5rem", width: "1.5rem", display: "inline-block" }}>
                  <span className="avatar-title rounded-2">
                    {cellProps.row.index + (currentPage - 1) * perPage + 1}
                  </span>
                </div>
              )}
            </div>
          );
        },
        size: 50,
      },
      {
        header: "Mentor Name",
        accessorKey: "mentorName",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => (
          <div style={{ padding: "12px" }}>
            {cellProps.getValue()}
          </div>
        ),
        size: 150,
      },
      {
        header: "Mentee Name",
        accessorKey: "menteeName",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => (
          <div style={{ padding: "12px" }}>
            {cellProps.getValue()}
          </div>
        ),
        size: 150,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          const status = cellProps.getValue();
          let statusClass = "";
          
          switch(status.toLowerCase()) {
            case "completed":
              statusClass = "bg-success";
              break;
            case "scheduled":
              statusClass = "bg-warning";
              break;
            case "canceled":
              statusClass = "bg-danger";
              break;
            default:
              statusClass = "bg-secondary";
          }
          
          return (
            <div style={{ padding: "12px" }}>
              <span className={`badge ${statusClass} font-size-12`}>
                {status}
              </span>
            </div>
          );
        },
        size: 100,
      },
      {
        header: () => (
          <div style={{ marginLeft: '15px' }}>
            Skills
          </div>
        ),
        accessorKey: "skills",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => (
          <SkillsCell skills={cellProps.row.original.skills} />
        ),
        size: 200,
      },
      {
        header: "Mentor Score",
        accessorKey: "mentorScore",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => (
          <div style={{ textAlign: "center", padding: "12px" }}>
            {cellProps.getValue()}
          </div>
        ),
        size: 100,
      },
      {
        header: "Mentee Score",
        accessorKey: "menteeScore",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => (
          <div style={{ textAlign: "center", padding: "12px" }}>
            {cellProps.getValue()}
          </div>
        ),
        size: 100,
      },
      {
        header: "Scheduled At",
        accessorKey: "scheduledAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => (
          <div style={{ padding: "12px" }}>
            {cellProps.getValue()}
          </div>
        ),
        size: 150,
      },
      {
        header: "Mentor Feedback",
        accessorKey: "mentorFeedback",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => (
          <FeedbackCell value={cellProps.getValue()} />
        ),
        size: 350,
      },
      {
        header: "Mentee Feedback",
        accessorKey: "menteeFeedback",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => (
          <FeedbackCell value={cellProps.getValue()} />
        ),
        size: 350,
      },
    ],
    [currentPage, perPage]
  );

  const handleFilterData = useCallback(() => {
    const filterQuery: any = {};

    if (status.length) {
      filterQuery["filter.status"] = status.map((status) => status.value);
    }

    if (orderBy) {
      filterQuery["filter.orderBy"] = orderBy["value"];
    }

    if (skills) {
      filterQuery["filter.skills"] = skills.map((skill) => skill.value);
    }

    if (mentorName) {
      filterQuery["filter.mentorName"] = mentorName;
    }

    if (menteeName) {
      filterQuery["filter.menteeName"] = menteeName;
    }

    dispatch(
      getOrgSessions({
        filterQuery,
        perPage: perPage,
        page: currentPage,
      })
    );
  }, [dispatch, currentPage, status, orderBy, skills, mentorName, menteeName, perPage]);

  const fetchSkills = async () => {
    await api
      .get("/api/v1/sessions/skills")
      .then((response: any) => {
        const options = response.map((group: any) => ({
          label: group.title,
          name: group.name,
          options: group.choices.map((choice: any) => ({
            label: choice.text,
            value: choice.value,
          })),
        }));
        setAllSkills(options);
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Error while fetching skills.");
      });
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData, perPage]);



  return (
        <Container fluid>
          <Row>
            <Col lg="12">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card className="mt-3">
                  <CardBody>
                  <CardTitle className="mb-4">Org Sessions</CardTitle>
                  <div className="container-fluid mt-4">
                    <div className="row g-3">
                      <div className="col-12 col-md-6 col-lg-2">
                        <input
                          type="text"
                          placeholder="Mentor Name"
                          className="form-control"
                          value={mentorName}
                          onChange={(e) => {
                            setMentorname(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-2">
                        <input
                          type="text"
                          placeholder="Mentee Name"
                          className="form-control"
                          value={menteeName}
                          onChange={(e) => {
                            setMenteename(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-2">
                        <Select
                          name="Status"
                          placeholder="Status"
                          isClearable
                          isMulti
                          options={statusData}
                          onChange={(selectedOptions) => {
                            selectedOptions === null
                              ? setStatus([])
                              : setStatus(selectedOptions);
                            setCurrentPage(1);
                          }}
                          value={status}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({ ...base, maxHeight: 300, width: 'auto'}),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected ? "#fff" : provided.backgroundColor,
                              color: state.isSelected ? "#000" : provided.color,
                            }),
                            
                          }}
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-4">
                        <Select
                        classNamePrefix="select2-selection modal-dialog-scrollable modal-dialog"
                          name="features"
                          placeholder="Choose Goals/Skills"
                          options={allSkills}
                          isMulti
                          onChange={(selectedOptions) => {
                            selectedOptions === null
                              ? setSkills([])
                              : setSkills(selectedOptions);
                            setCurrentPage(1);
                          }}
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                            backgroundColor: "white",
                            cursor: "pointer",
                            }),
                          menu: (provided, state) => ({
                            ...provided,
                            backgroundColor: "white",
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            placeholder: (styles) => ({
                              ...styles,
                            }),
                          }}
                        menuPortalTarget={document.body}
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-2">
                        <Select
                          name="Order By"
                          placeholder="Order By"
                          isClearable
                          options={orderByData}
                          onChange={(selectedOption) => {
                            selectedOption === null
                              ? setOrderBy("")
                              : setOrderBy(selectedOption);
                            setCurrentPage(1);
                          }}
                          value={orderBy}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({ ...base, maxHeight: 300 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected ? "#fff" : provided.backgroundColor,
                              color: state.isSelected ? "#000" : provided.color,
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                    <OrgSessionsTable
                      columns={columns}
                      data={orgSessions?.sessions || []}
                      tableClass="table-sm align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      buttonClass="btn btn-primary btn-sm addContact-modal mb-2 text-white"
                      buttonName="Invite User"
                      currentPage={currentPage}
                      totalPages={orgSessions.totalPages}
                      setCurrentPage={setCurrentPage}

                    />
                    <Paginations
                      perPageData={perPage}
                      setPerPage={setPerPage}
                      totalDataCount={orgSessions?.totalSessions}
                      totalPages={orgSessions?.totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={true}
                      paginationClass="pagination pagination-rounded"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
  );
};

export default OrgSessions;
