import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartDynamicColor";

const CrossDepBarChart = ({ chartData, dataColors, xAxisLabel, titleText, yAxisLabel }: any) => {
    const spineareaChartColors = getChartColorsArray(dataColors);
  // Check if chartData is an array
  // console.log("chartData:", chartData);

  // ✅ Ensure chartData is a valid array
  if (!Array.isArray(chartData) || chartData.length === 0) {
    // console.error("Invalid or empty chartData:", chartData);
    return <p>No data available</p>;
  }

 
  // ✅ Transform data for the chart
  const categories = chartData.map((item: any) =>
    item.mentorDepartment && item.menteeDepartment
      ? `${item.mentorDepartment} → ${item.menteeDepartment}`
      : "Unknown"
  );

  const data = chartData.map((item: any) => Number(item.sessionCount) || 0); // ✅ Ensure numbers


  const series = [
    {
      name: "Session Count",
      data,
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: { show: true },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: false } },
      borderColor: "#f1f1f1",
    },
    title: {
      text: "Cross Department Session Stats",
      align: "left",
    },
    xaxis: {
        categories, // ✅ Move categories here!
        title: { text: "Session Count" },
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: { rotate: 0, show: true },
      },
    yaxis: {
        title: { text: "Mentor Dep → Mentee Dep" },
    },
    colors: spineareaChartColors,
  };

  return <ReactApexChart options={options} series={series} type="bar" className="apex-charts"  height={500} />;
};

export default CrossDepBarChart;
