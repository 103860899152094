import React from "react";
import WordCloud from "react-wordcloud";

const WordCloudComponent = ({ wordCloud }) => {
  // Convert object to required format
  const wordCloudData: { text: string; value: number }[] = wordCloud
  ? Object.entries(wordCloud).map(([text, value]) => ({
      text,
      value: Number(value), // Ensure value is a number
    }))
  : [];


  const options = {
    rotations: 2,
    rotationAngles: [-90, 0] as [number, number], // ✅ Explicit tuple
    fontSizes: [20, 50] as [number, number], // ✅ Explicit tuple
    
  };


  return (
  <div>
    <WordCloud words={wordCloudData} options={options} />
  </div>
  );
};

export default WordCloudComponent;