import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

const api = new APIClient();


export const fetchReport = createAsyncThunk(
  "reports/fetchReport",

  async (filterData: any ) => {
    try {
    

      if(filterData.reportType === "top_mentors") {
        const response = await api.get(`/api/v1/reports/top-mentors`,  {
          params: filterData,
        });
        return response;
      }else if(filterData.reportType === "top_mentees") {
        const response = await api.get(`/api/v1/reports/top-mentees`,  {
          params: filterData,
        });
        return response;
      }else if(filterData.reportType === "skill_trends") {
        const response = await api.get(`/api/v1/reports/skill-trends`,  {
          params: filterData,
        });
        return response;
      } else if(filterData.reportType === "active_sessions") {
        const response = await api.get(`/api/v1/reports/active-sessions`,  {
          params: filterData,
        });
        return response;
      }else if(filterData.reportType === "completed_sessions") {
        const response = await api.get(`/api/v1/reports/completed-sessions`,  {
          params: filterData,
        });
        return response;
      }else if(filterData.reportType === "cancelled_sessions") {
        const response = await api.get(`/api/v1/reports/cancelled-sessions`,  {
          params: filterData,
        });
        return response;
      }else{
      
        return {
          reportData: [],
          columns: [],
          loading: false,
          error: null,
          total: 0,
            perPage: 10,
            page: 1,
            totalPages: 0,
        };
      }
    
    } catch (error) {
      return error;
    }
  }
);

export const downloadCsv = createAsyncThunk(
  "reports/downloadCsv",
  async (filterData: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await api.get("/api/v1/reports/download-csv", {
        params: filterData, // Pass filters as query parameters
        responseType: "blob", // Required for file downloads
      });

      // Extract reportType from filterData or fallback to 'report'
      const reportType = filterData.reportType || "report";
      const fileName = `${reportType}.csv`;

      
      // Ensure we use response.data, not response directly
      const blob = new Blob([response], { type: "text/csv" });
      saveAs(blob, fileName);

      return response.data;
    } catch (error: any) {
      toast.error("Something went wrong while downloading the CSV", {
        autoClose: 3000,
      });

      return rejectWithValue(error.response?.data || "Download failed");
    }
  }
);