import React, { useState } from "react";
import PieChart from "Components/charts/PieChart";
import {
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useAppSelector } from "slices";

export default function Demographics() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDemographic, setSelectedDemographic] = useState("gender");
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { demographics } = useAppSelector((state) => state.Analytics);

  return (
    <Card className="flex-grow-1">
      <CardTitle className="m-4 flex flex-row">
        <div>Demographics</div> 
        {demographics && (
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
              {selectedDemographic.charAt(0).toUpperCase() +
                selectedDemographic.slice(1)}
              <i
                className="mdi mdi-chevron-down d-none d-xl-inline-block"
                style={{ marginLeft: "5px" }}
              />
            </DropdownToggle>
            <DropdownMenu>
              {Object.keys(demographics).map((key) => (
                <DropdownItem
                  key={key}
                  onClick={() => setSelectedDemographic(key)}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      </CardTitle>
      {demographics && demographics?.[selectedDemographic] && (
        <CardBody>
          <PieChart
            data={demographics?.[selectedDemographic] ?? []}
            dataColors='["--bs-success","--bs-primary", "--bs-danger","--bs-info", "--bs-warning"]'
          />
        </CardBody>
      )}
    </Card>
  );
}
