import React, { useState } from 'react';

const FeedbackCell = ({ value }: { value: any }) => {
  const [expanded, setExpanded] = useState(false);

  const feedbackEntries = Object.entries(value);
  if (feedbackEntries.length === 0) {
    return <div className="text-center">NA</div>;
  }
  const hasLongContent = feedbackEntries.length > 2;
  
  const displayEntries = expanded ? feedbackEntries : feedbackEntries.slice(0, 2);
  
  return (
    <div style={{
      width: "100%",
      padding: "10px",
      position: "relative",
      minHeight: "60px"
    }}>
      {displayEntries.map(([key, val]) => {
        const formattedKey = key
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        
        const isNumeric = !isNaN(Number(val)) && val !== "";
        const displayValue: string = isNumeric ? `${String(val)}/5` : String(val);
        
        return (
          <div key={key} style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
            borderBottom: "1px solid #f0f0f0"
          }}>
            <span style={{
              fontWeight: 500,
              paddingRight: "10px"
            }}>
              {formattedKey}:
            </span>
            <span style={{
              textAlign: "right"
            }}>
              {displayValue}
            </span>
          </div>
        );
      })}
      
      {hasLongContent && (
        <div 
          onClick={() => setExpanded(!expanded)} 
          style={{
            textAlign: "center",
            color: "#4a6cf7",
            cursor: "pointer",
            fontWeight: 500,
            marginTop: "5px",
            padding: "2px 0",
            borderTop: "1px dashed #e0e0e0"
          }}
        >
          {expanded ? "Show Less" : `Show ${feedbackEntries.length - 2} More`}
        </div>
      )}
    </div>
  );
};

export default FeedbackCell;