import { createSlice } from "@reduxjs/toolkit";
import {
  getAnalyticsCount,
  getMenteeAvgScore,
  getMentorAvgScore,
  getSessionByDay,
  getUsersByDomain,
  getSessionByDuration,
  getSessionByFeedbackScore,
  getUserAnalytics,
  getUserAnalyticsRating,
  getUserAnalyticsType,
  getUserReviews,
  getUserSessionByMonth,
  getSkillTrends,
  getCrossDepartmentSessionStats,
  getWordFrequency,
  getEngagementTrend
} from "./thunk";

interface AnalyticsCount {
  mentees?: number;
  mentors?: number;
  totalUsers?: number;
  totalSessions?: number;
  totalUpcomingSessions?: number;
  totalHours?: number;
  both?: number;
  ratings?: Record<string, number>;
  totalMatchUsers?: number;
}

interface Demographics {
  gender: {
    [key: string]: number;
  };
  department: {
    [key: string]: number;
  };
  location: {
    [key: string]: number;
  };
}

interface DomainStats {
  menteeCount: number;
  mentorCount: number;
}

interface KeyDomains {
  [domain: string]: DomainStats;
}

interface UserByDomain {
  keyDomainsByMentee: KeyDomains;
  keyDomainsByMentor: KeyDomains;
}


interface UserAnalytics {
  usersData: any[];
  total: number;
  perPage: number;
  page: number;
  totalPages: number;
}

interface AnalyticsState<T = number> {
  loading: boolean;
  analyticsCount: AnalyticsCount;
  totalSessions?: {
    [key: string]: T;
  };
  totalSessionsByFeedback?: {
    [key: string]: T;
  };
  demographics?: Demographics;
  userAnalytics?: UserAnalytics;
  reviews?: any[];
  ratingsDistribution?: Record<string, any[]>;
  ratingsByMonth?: Record<string, any[]>;
  menteeAvgScore?: number;
  mentorAvgScore?: number;
  userByDomain?: UserByDomain;
  skillsTrends?: any[];
  crossDepartmentSessionStats?: any[];
  wordCloud?: any[];
  engagementData?:any[];
}

const initialState: AnalyticsState = {
  loading: false,
  userAnalytics: {
    usersData: [],
    total: 0,
    perPage: 10,
    page: 1,
    totalPages: 0,
  },
  analyticsCount: {
    mentees: 0,
    mentors: 0,
    totalUsers: 0,
    totalSessions: 0,
    totalUpcomingSessions: 0,
    totalHours: 0,
    both: 0,
    totalMatchUsers:0,
  },
  reviews: [],
  totalSessions: {},
  totalSessionsByFeedback: {},
  ratingsByMonth: {},
  demographics: {
    gender: {
      male: 1,
      female: 1,
    },
    department: {
      IT: 1,
      HR: 1,
    },
    location: {
      "New York": 1,
      "San Francisco": 1,
    },
  },
  menteeAvgScore: 0,
  mentorAvgScore: 0,
  userByDomain: {
    keyDomainsByMentee: {},
    keyDomainsByMentor: {},
  },
  skillsTrends: [],
  crossDepartmentSessionStats: [],
  wordCloud: [],
  engagementData:[]
};

const analyticsSlice = createSlice({
  name: "Analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsCount.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAnalyticsCount.fulfilled, (state, action) => {
      state.loading = false;
      state.analyticsCount = action.payload;
      console.log("analyticsCount:", action.payload);
    });
    builder.addCase(getAnalyticsCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionByDuration.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionByDuration.fulfilled, (state, action) => {
      state.loading = false;
      state.totalSessions = action.payload;
    });
    builder.addCase(getSessionByDuration.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionByFeedbackScore.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionByFeedbackScore.fulfilled, (state, action) => {
      state.loading = false;
      state.totalSessionsByFeedback = action.payload;
    });
    builder.addCase(getSessionByFeedbackScore.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSkillTrends.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSkillTrends.fulfilled, (state, action) => {
      state.loading = false;
      state.skillsTrends = action.payload;
    });
    builder.addCase(getSkillTrends.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getEngagementTrend.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getEngagementTrend.fulfilled, (state, action) => {
      state.loading = false;
      state.engagementData = action.payload;
    });
    builder.addCase(getEngagementTrend.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getCrossDepartmentSessionStats.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getCrossDepartmentSessionStats.fulfilled, (state, action) => {
      state.loading = false;
      state.crossDepartmentSessionStats = action.payload;
    });
    builder.addCase(getCrossDepartmentSessionStats.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionByDay.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionByDay.fulfilled, (state, action) => {
      state.loading = false;
      state.demographics = action.payload;
    });
    builder.addCase(getSessionByDay.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUsersByDomain.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUsersByDomain.fulfilled, (state, action) => {
      state.loading = false;
      state.userByDomain = action.payload;

    });
    builder.addCase(getUsersByDomain.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserAnalytics.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserAnalytics.fulfilled, (state, action) => {
      state.loading = false;
      state.userAnalytics = action.payload;
    });
    builder.addCase(getUserAnalytics.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserAnalyticsType.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserAnalyticsType.fulfilled, (state, action) => {
      state.analyticsCount = action.payload;
    });
    builder.addCase(getUserAnalyticsType.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getWordFrequency.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getWordFrequency.fulfilled, (state, action) => {
      state.wordCloud = action.payload;
    });
    builder.addCase(getWordFrequency.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserReviews.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserReviews.fulfilled, (state, action) => {
      state.reviews = action.payload?.reviews;
    });
    builder.addCase(getUserReviews.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserAnalyticsRating.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserAnalyticsRating.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserAnalyticsRating.fulfilled, (state, action) => {
      state.loading = false;
      state.ratingsDistribution = action.payload;
    });
    builder.addCase(getUserSessionByMonth.fulfilled, (state, action) => {
      state.loading = false;
      state.ratingsByMonth = action.payload;
    });
    builder.addCase(getMenteeAvgScore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMenteeAvgScore.fulfilled, (state, action) => {
      state.loading = false;
      state.menteeAvgScore = action.payload.menteeAvgScore;
    });
    builder.addCase(getMenteeAvgScore.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMentorAvgScore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMentorAvgScore.fulfilled, (state, action) => {
      state.loading = false;
      state.mentorAvgScore = action.payload.mentorAvgScore;
    });
    builder.addCase(getMentorAvgScore.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default analyticsSlice.reducer;
