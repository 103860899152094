import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentChatBoxUserAction } from "../../slices/chats/reducer";
import { MessageCircle } from "lucide-react";
import { Tooltip } from "react-tooltip";

const ChatIcon = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChatClick = () => {
    dispatch(
      setCurrentChatBoxUserAction({
        name: `${user.firstName} ${user.lastName}`,
        roomId: user.id,
        online: user.online,
        isMentor: user.isMentor,
      })
    );
    navigate("/chat");
  };

  const statusClass = user.online ? "text-success" : "text-secondary";
  const tooltipId = `chat-tooltip-${user.id}`;

  return (
    <>
      <button
        onClick={handleChatClick}
        data-tooltip-id={tooltipId}
        data-tooltip-content={`Chat with ${user.firstName} ${user.lastName}`}
        className={`btn btn-sm border-0 rounded-circle d-flex align-items-center justify-content-center p-2 chat-icon-button ${statusClass}`}
        aria-label={`Chat with ${user.firstName} ${user.lastName}`}
      >
        <MessageCircle size={16} />
      </button>
      
      <Tooltip id={tooltipId} />
    </>
  );
};

export default ChatIcon;