import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartDynamicColor";

const HorizontalSmallBarChart = ({ data, dataColors, xAxisLabel, titleText, yAxisLabel }: any) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  const categories = Object.keys(data);
  const menteeCounts = categories.map((key) => data[key].menteeCount);
  const mentorCounts = categories.map((key) => data[key].mentorCount);
  const doamins = categories.map((key) => data[key].domain);

  const series = [{
    data: menteeCounts, name: 'Mentees'
  }, {
    data: mentorCounts, name: 'Mentors'
  }];
  const options: ApexCharts.ApexOptions = {
    chart: {
        type: 'bar',
        height: 430,
        toolbar: {
            tools: { 
                download: true, 
                zoomin: true,
                zoomout: true,

            },
        }
      },
      title: {
        text: titleText,
        align: "left",
        style: {
          fontWeight: "500",
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
          barHeight: '70%',
        }
      },
      dataLabels: { 
        enabled: true,
        offsetX: -20,
        style: {
            
          fontSize: '8px',
          colors: ['#000']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      
      xaxis: {
        title: { 
            text: xAxisLabel,
          },
        categories: doamins
      },
      yaxis: {
        title: {
          text: yAxisLabel,
        },
      },
      colors: spineareaChartColors,
  };

  return <ReactApexChart options={options} series={series} type="bar"  className="apex-charts" />;
};

export default HorizontalSmallBarChart;
 