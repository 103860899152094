import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartDynamicColor";

const PieChart = ({ dataColors, data }: any) => {
  const PieApexChartColors = getChartColorsArray(dataColors);

  const series = Object.values(data) as number[];
  const labels = Object.keys(data);

  const options: any = {
    chart: {
      height: 320,
      type: "pie",
    },
    labels: labels,
    colors: PieApexChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left",
      fontSize: "14px",
      itemMargin: { horizontal: 5, vertical: 5 }
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => `${val.toFixed(1)}%`,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => val.toFixed(2),
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["#fff"],
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 260,
          },
          legend: {
            show: false,
          },
        }
      },
    ],
  };

  return <ReactApexChart options={options} series={series} type="pie" height="360" />;
};

export default PieChart;
