import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartDynamicColor";
import { UncontrolledTooltip } from "reactstrap";

// Define expected data structure
interface EngagementData {
  [year: string]: {
    engagementPercent: number; // 0% to 100%
    activeUsers: number;
    totalUsers: number;
  };
}

// Define Props
interface EngagementTrendChartProps {
  engagementData: any; // Received from API (unknown structure)
  dataColors: string;
  xAxisLabel: string;
  titleText: string;
  yAxisLabel: string;
}

const EngagementTrendChart: React.FC<EngagementTrendChartProps> = ({
  engagementData,
  dataColors,
  xAxisLabel,
  titleText,
  yAxisLabel,
}) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  // ✅ Safely typecast engagementData
  const formattedData: EngagementData = engagementData as EngagementData;

  // ✅ Convert engagementData into ApexCharts series format
  const series = [
    {
      name: "Engagement %",
      data: Object.entries(formattedData).map(([year, data]) => ({
        x: year,
        y: Math.min(100, Math.max(0, data.engagementPercent)), // Ensure between 0-100%
        activeUsers: data.activeUsers,
        totalUsers: data.totalUsers,
      })),
    },
  ];

  // ✅ Extract X-axis categories (years)
  const domains = Object.keys(formattedData);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line", 
      height: 300,
    zoom: { enabled: false },
    toolbar: {
      show: true,
      offsetX: -20, // Move left or right
      offsetY: -20,  // Move up or down
      tools: {
        download: true, // Keep default download buttons
      }, 
    }, 
    },
    title: {
      text: "", // ❌ Remove title from ApexCharts
    },
    plotOptions: {
      bar: { 
        horizontal: false,
        dataLabels: { position: "top" },
        columnWidth: "50%",
        barHeight: "50%",
      },
       
    },
    dataLabels: {
      enabled: true,
      offsetY: -12,
      style: {
        fontSize: "8px",
        colors: ["#000"],
      },
      formatter: (val: number) => `${val.toFixed(2)}%`, // ✅ Always show percentage
    },
  
    tooltip: {
      shared: true, 
      intersect: false,
      y: {
        formatter: (val: number, opts) => {
          const index = opts.dataPointIndex;
          const activeUsers = series[0].data[index]?.activeUsers || 0;
          const totalUsers = series[0].data[index]?.totalUsers || 0;
          return `${val.toFixed(2)}% (Active: ${activeUsers}, Total: ${totalUsers})`;
        },
      },
    },
    xaxis: {
      title: { text: xAxisLabel },
      categories: domains,
      labels: {
        rotate: -45,
        style: { fontSize: "12px" },
      },
    },
    yaxis: {
      title: { text: yAxisLabel },
      min: 0, // ✅ Ensure Y-axis starts at 0%
      max: 100, // ✅ Ensure Y-axis does not exceed 100%
      labels: {
        formatter: (val) => `${val}%`, // Show as percentage
      },
    },
    markers: { size: 8 },
    stroke: { width: 3, curve: "smooth" },
    colors: spineareaChartColors,
  };

  return (
    <div>
      {/* ✅ Custom Title with Tooltip */}
      <div className="d-flex align-items-center mb-2">
        <h5 className="m-0" id="chartTitleTooltip">
          {titleText}
          <i className="mdi mdi-information-outline ms-2" />
        </h5>
      </div>

      <UncontrolledTooltip placement="top" target="chartTitleTooltip">
      Engagement % is calculated as: (Active Users / Total Users) × 100. It represents the percentage of users actively participating.
      </UncontrolledTooltip>

      {/* ✅ Chart Below the Title */}
      <ReactApexChart options={options} series={series} type="line" height={300} />
    </div>
  );
};

export default EngagementTrendChart;
