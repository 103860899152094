import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchMySessions, fetchRequestedSessions, fetchSessionNotes } from "./thunk";

interface SessionListState {
  sessionProfile: any;
  requestedSessions: any;
  sessions: any;
  sessionNotes: { [sessionId: string]: any[] };
  page: number;
  perPage: number;
}

const initialState: SessionListState = {
  sessionProfile: null,
  requestedSessions: [],
  sessions: [],
  sessionNotes: {},
  page: 1,
  perPage: 10,
};

const sessionSlice = createSlice({
  name: "SessionList",
  initialState,
  reducers: {
    fetchProfileSuccess(state, action: any) {
      state.sessionProfile = action.payload;
    },
    setSessionRequests(state, action: any) {
      state.requestedSessions = action.payload;
    },
    setSessions(state, action: any) {
      state.sessions = action.payload;
    },
    setPageForSessions(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPerPageForSessions(state, action: PayloadAction<number>) {
      state.perPage = action.payload;
    },
    setSessionNotes(state, action: PayloadAction<{ sessionId: string; note: any }>) {
      const { sessionId, note } = action.payload;
      if (!state.sessionNotes[sessionId]) {
        state.sessionNotes[sessionId] = [];
      }

      state.sessionNotes[sessionId] = [...state.sessionNotes[sessionId], note];
    },
    removeSessionNote(state, action: PayloadAction<{ sessionId: string; noteId: string }>) {
      const { sessionId, noteId } = action.payload;
      if (state.sessionNotes[sessionId]) {
        state.sessionNotes[sessionId] = state.sessionNotes[sessionId].filter(
          (note) => note.id !== noteId
        );
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMySessions.fulfilled, (state, action) => {
      state.sessions = action.payload.sessions;
    });
    builder.addCase(fetchMySessions.rejected, (state, action) => {
      state.sessions = [];
    });
    builder.addCase(fetchRequestedSessions.fulfilled, (state, action) => {
        state.requestedSessions = action.payload.requests;
    });
    builder.addCase(fetchRequestedSessions.rejected, (state, action) => {
      state.requestedSessions = [];
    });
    builder.addCase(fetchSessionNotes.fulfilled, (state, action) => {
      const { sessionId, notes } = action.payload;

      state.sessionNotes[sessionId] = Array.isArray(notes) ? 
        notes : [];
    });
  },
});

export const {
  fetchProfileSuccess,
  setSessionRequests,
  setSessions,
  setPerPageForSessions,
  setPageForSessions,
  setSessionNotes,
  removeSessionNote
} = sessionSlice.actions;

export default sessionSlice.reducer;
