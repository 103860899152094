import React, { useCallback, useEffect, useMemo, useState } from "react";
import LineChart from "Components/charts/ApexLineChart";
import withRouter from "Components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

//Import mini card widgets
import MiniCards from "../../Dashboard/mini-card";


import { useAppDispatch, useAppSelector } from "slices";
import TableContainer from "Components/Common/TableContainer";
import {
  getAnalyticsCount,
  getMenteeAvgScore,
  getMentorAvgScore,
  getSessionByDay,
  getUsersByDomain,
  getSessionByDuration,
  getSessionByFeedbackScore,
  getUserAnalytics,
  getSkillTrends,
  getEngagementTrend,
  getCrossDepartmentSessionStats,
  getWordFrequency,
} from "slices/datasummary/thunk";
import Paginations from "Components/Common/Pagination";
import Select from "react-select";
import { fetchOrgMetaData } from "slices/onboarding/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import VerticalBarChart from "Components/charts/VerticalBarChart";
import HorizontalSmallBarChart from "Components/charts/HorizontalSmallBarChart";
import SkillTrendLineChart from "Components/charts/SkillTrednLineChart";
import CrossDepBarChart from "Components/charts/CrossDepStats";
import WordCloudComponent from "Components/Common/WordCloudComponent";
import EngagementTrendChart from "Components/charts/EngagementTrendChart";
import DemographicsForDataSummary from "pages/Dashboard/Components/DemographicsForDataSummary";
import Flatpickr from "react-flatpickr";
const DataSummary = () => {
  //meta title
  document.title = "Data Summary | Mentorgain";
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date>(new Date()); // ✅ Default to today's date

  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const userTypesData: any[] = [
    { label: "Mentor", value: "mentor" },
    { label: "Mentee", value: "mentee" },
    { label: "Both", value: "both" },
  ];
  const orderByData: any[] = [
    { label: "Type", value: "type" },
    { label: "Department", value: "department" },
    { label: "Number of sessions", value: "numMentorshipSessions" },
    { label: "Satisfaction Score", value: "satisfactionScore" },
  ];
  const orderData: any[] = [
    { label: "ASC", value: "asc" },
    { label: "DESC", value: "desc" },
  ];
  const [userTypes, setUserTypes] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [userType, setUserType] = useState<{ label: string; value: string }>({
    label: "Mentee",
    value: "mentee",
  });

  const {
    analyticsCount,
    totalSessions,
    totalSessionsByFeedback,
    userAnalytics,
    menteeAvgScore,
    mentorAvgScore,
    userByDomain,
    skillsTrends,
    engagementData,
    crossDepartmentSessionStats,
    wordCloud,
  } = useAppSelector((state) => state.DataSummary);

 


  const miniCards = [
    {
      title: "Only Mentees",
      iconClass: "bx-check-circle",
      text: analyticsCount?.mentees,
    },
    {
      title: "Only Mentors",
      iconClass: "bx-hourglass",
      text: analyticsCount?.mentors,
    },
    {
      title: "Both",
      iconClass: "bx-package",
      text: analyticsCount?.both,
    },

    {
      title: "Mentee Average Score",
      iconClass: "bx-package",
      text: menteeAvgScore,
    },
    {
      title: "Mentor Average Score",
      iconClass: "bx-package",
      text: mentorAvgScore,
    },

    {
      title: "Completed Sessions",
      iconClass: "bx-package",
      text: analyticsCount?.totalSessions,
    },
    {
      title: "Upcoming Sessions",
      iconClass: "bx-package",
      text: analyticsCount?.totalUpcomingSessions,
    },
    {
      title: "Total Matched Users",
      iconClass: "bx-package",
      text: analyticsCount?.totalMatchUsers,
    },
  ];

  const formatDate = (date) => {
    if (!date) return "";
    
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(d.getDate()).padStart(2, "0"); // Ensure 2-digit day
  
    return `${year}-${month}-${day}`;
  };
const formattedFromDate = formatDate(fromDate); 
const formattedToDate = formatDate(toDate); 

  useEffect(() => {
    dispatch(getAnalyticsCount({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getSessionByDuration({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getSessionByFeedbackScore({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getSkillTrends({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getEngagementTrend({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getCrossDepartmentSessionStats({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getUsersByDomain({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getSessionByDay({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getUsersByDomain({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getMenteeAvgScore({fromDate: formattedFromDate, toDate: formattedToDate}));
    dispatch(getMentorAvgScore({fromDate: formattedFromDate, toDate: formattedToDate})); 
    dispatch(fetchOrgMetaData());
  }, [dispatch, formattedFromDate, formattedToDate]);
  
  useEffect(() => {
    dispatch(getWordFrequency({ userType: userType.value, fromDate: formattedFromDate, toDate: formattedToDate }));
  }, [dispatch, userType, formattedFromDate, formattedToDate]);

  const handleFilterData = useCallback(() => {
    const filterQuery: any = {};

    if (userTypes.length) {
      filterQuery["filter.userTypes"] = userTypes.map(
        (userType) => userType.value
      );
    }

    if (departments.length) {
      filterQuery["filter.departments"] = departments.map(
        (department) => department.value
      );
    }

    if (orderBy) {
      filterQuery["filter.orderBy"] = orderBy["value"];
    }

    if (
      orderBy === undefined ||
      orderBy["value"] === null ||
      orderBy["value"] === ""
    ) {
      filterQuery["filter.orderBy"] = "numMentorshipSessions";
      setOrderBy({
        label: "Number of sessions",
        value: "numMentorshipSessions",
      });
    }

    if (order) {
      filterQuery["filter.order"] = order["value"];
    }

    if (
      order === undefined ||
      order["value"] === null ||
      order["value"] === ""
    ) {
      filterQuery["filter.order"] = "desc";
      setOrder({ label: "DESC", value: "desc" });
    }

    dispatch(
      getUserAnalytics({
        filterQuery,
        perPage: perPage,
        page: currentPage
      })
    );
  }, [dispatch, currentPage, userTypes, departments, orderBy, order, perPage]);

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData]);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Department",
        accessorKey: "department",
        enableColumnFilter: false,
        enableSorting: false,
      },
      { 
        header: "Number of sessions",
        accessorKey: "numMentorshipSessions",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Satisfaction Score",
        accessorKey: "satisfactionScore",
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  

  const keyDomainColumns = useMemo(
    () => [
      {
        header: "Domain",
        accessorKey: "domain",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mentees need hep",
        accessorKey: "menteeCount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mentor with expertise",
        accessorKey: "mentorCount",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  );

  const keyDomainMenteeData = useMemo(() => {
    if (!userByDomain?.keyDomainsByMentee) return [];

    return Object.entries(userByDomain.keyDomainsByMentee).map(
      ([domain, counts]) => ({
        domain,
        menteeCount: counts.menteeCount, // Extract menteeCount
        mentorCount: counts.mentorCount, // Extract mentorCount
      })
    );
  }, [userByDomain]);

  const keyDomainMentorData = useMemo(() => {
    if (!userByDomain?.keyDomainsByMentor) return [];

    return Object.entries(userByDomain.keyDomainsByMentor).map(
      ([domain, counts]) => ({
        domain,
        menteeCount: counts.menteeCount, // Extract menteeCount
        mentorCount: counts.mentorCount, // Extract mentorCount
      })
    );
  }, [userByDomain]);

  const selectOrgMetaData = createSelector(
    (state: any) => state.Onboarding,
    (onboarding) => ({
      orgDepartments: onboarding.departments,
    })
  );
  const { orgDepartments } = useSelector(selectOrgMetaData);
  const departmentsData = orgDepartments.map((department) => ({
    label: department.title,
    value: department.value,
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <div className="flex w-full justify-end items-center gap-4 mb-4" style={{ display: "flex" , justifyContent:"end"}}>
        
          <label className="block text-sm font-medium" style={{ alignContent: 'center' }}>From Date:</label>
          <Flatpickr 
            value={fromDate || undefined} 
            onChange={([date]) => setFromDate(date)} 
            options={{ dateFormat: "Y-m-d" }} 
            className="border p-2 rounded-md"
            placeholder="Select a date"  
          />
        
        
          <label className="block text-sm font-medium" style={{ marginLeft: '2rem', alignContent: 'center'  }}>To Date:</label>
          <Flatpickr 
            value={toDate} 
            onChange={([date]) => setToDate(date)} 
            options={{ dateFormat: "Y-m-d" }} 
            className="border p-2 rounded-md"
          />
        
      </div>
          <Row>
            {/* <Col xl={4} className="d-flex flex-column">
            <WelcomeCard />
            <Demographics />
          </Col> */}

            <Col xl={12}>
              <Row>
                {(miniCards || []).map((card: any, key: number) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    colMd={3}
                    key={"_card_" + key}
                  />
                ))} 
              </Row>
              <Row>
                <Col xl={6}  className="d-flex" >
                  <DemographicsForDataSummary />
                </Col>
                <Col xl={6}  className="d-flex" >
                  <Card className="flex-grow-1">
                    <CardBody>
                      
                      <div className="col-6 col-md-3 mb-2">
                      <div style={{ fontWeight: "600",}}>Word Cloud</div> 
                        <Select
                          name="User Type"
                          placeholder="User Type"
                          options={[
                            { label: "Mentee", value: "mentee" },
                            { label: "Mentor", value: "mentor" },
                          ]}
                          onChange={(selectedOption) => {
                            selectedOption === null
                              ? setUserType({
                                  label: "Mentee",
                                  value: "mentee",
                                })
                              : setUserType(selectedOption);
                          }}
                          value={userType}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({ ...base, maxHeight: 300 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#fff"
                                : provided.backgroundColor,
                              color: state.isSelected ? "#000" : provided.color,
                            }),
                          }}
                        />
                      </div>
                      {wordCloud && Object.keys(wordCloud).length > 0 ? (
  <WordCloudComponent wordCloud={wordCloud} />
) : (
  <div style={{display:"flex", justifyContent: 'center'}}>No data found</div>
)}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xl={12}>
                  <Card className="mt-3">
                    <CardBody>
                      <EngagementTrendChart
                        engagementData={engagementData}
                        dataColors='["--bs-success","--bs-primary"]'
                        yAxisLabel="Engagement Percentage"
                        xAxisLabel=""
                        titleText="Mentorship Engagement Trend"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="g-3">
                <Col xl={6}>
                  <Card className="mt-3">
                    <CardBody>
                      <div id="revenue-chart">
                        <LineChart
                          series={[
                            {
                              name: "Mentorship Session",
                              data: Object.values(
                                totalSessions as any
                              ) as number[],
                            },
                          ]}
                          categories={
                            Object.keys(totalSessions as any) as string[]
                          }
                          titleText={"Total sessions completed over time"}
                          yAxisLabel={"Session Count"}
                          dataColors='["--bs-success","--bs-primary"]'
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card className="mt-3">
                    <CardBody>
                      <div id="revenue-chart">
                        <VerticalBarChart
                          series={[
                            {
                              name: "Session Count",
                              data: Object.values(
                                (totalSessionsByFeedback as any) || {}
                              ),
                            },
                          ]}
                          categories={Object.keys(
                            (totalSessionsByFeedback as any) || {}
                          )}
                          titleText={"Session Feedback Score"}
                          yAxisLabel={"Session Count"}
                          xAxisLabel={"Score"}
                          dataColors='["--bs-success","--bs-primary"]'
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* Full Width User Activity Statistics */}
            <Col xs={12}>
              <Card className="mt-3">
                <CardBody>
                  <CardTitle className="mb-4">
                    Top Participants (By Completed Sessions)
                  </CardTitle>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="col-6 col-md-3 mb-2">
                      <Select
                        name="Type"
                        placeholder="User Type"
                        isClearable
                        isMulti
                        options={userTypesData}
                        onChange={(selectedOptions) => {
                          selectedOptions === null
                            ? setUserTypes([])
                            : setUserTypes(selectedOptions);
                          setCurrentPage(1);
                        }}
                        value={userTypes}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                      <Select
                        name="Department"
                        placeholder="Department"
                        isClearable
                        isMulti
                        options={departmentsData}
                        onChange={(selectedOptions) => {
                          selectedOptions === null
                            ? setDepartments([])
                            : setDepartments(selectedOptions);
                          setCurrentPage(1);
                        }}
                        value={departments}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                      <Select
                        name="Order By"
                        placeholder="Order By"
                        isClearable
                        options={orderByData}
                        onChange={(selectedOption) => {
                          selectedOption === null
                            ? setOrderBy("")
                            : setOrderBy(selectedOption);
                          setCurrentPage(1);
                        }}
                        value={orderBy}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>

                    <div className="col-6 col-md-2 mb-2">
                      <Select
                        name="Order"
                        placeholder="Order"
                        isClearable
                        options={orderData}
                        onChange={(selectedOption) => {
                          selectedOption === null
                            ? setOrder("")
                            : setOrder(selectedOption);
                          setCurrentPage(1);
                        }}
                        value={order}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>
                  </div>

                  <TableContainer
                    columns={columns}
                    data={userAnalytics?.usersData || []}
                    tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    currentPage={currentPage}
                    totalPages={userAnalytics?.totalPages}
                    setCurrentPage={setCurrentPage}
                  />

                  <Paginations
                    perPageData={perPage}
                    setPerPage={setPerPage}
                    totalDataCount={userAnalytics?.total}
                    totalPages={userAnalytics?.totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isShowingPageLength={true}
                    paginationClass="pagination pagination-rounded"
                  />
                </CardBody>
              </Card>
            </Col>

            <Row>
              <Col xs={6}>
                <Card className="mt-3">
                  <CardBody>
                    <CardTitle className="mb-4">
                      Top Mentee Area For Development
                    </CardTitle>

                    <TableContainer
                      columns={keyDomainColumns}
                      data={keyDomainMenteeData || []}
                      tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6}>
                <Card className="mt-3">
                  <CardBody>
                    <CardTitle className="mb-4">
                      Top Mentors with Area of Expertise
                    </CardTitle>

                    <TableContainer
                      columns={keyDomainColumns}
                      data={keyDomainMentorData || []}
                      tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Col xl={12}>
              <Card className="mt-3">
                <CardBody>
                  <div id="revenue-chart">
                    <HorizontalSmallBarChart
                      data={keyDomainMentorData}
                      categories={
                        Object.keys(keyDomainMentorData as any) as string[]
                      }
                      titleText={"Mentees Vs Mentors registered by domain"}
                      dataColors='["--bs-success","--bs-primary"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
              <Card className="mt-3">
                <CardBody>
                  <div id="revenue-chart">
                    <SkillTrendLineChart
                      data={skillsTrends}
                      // titleText={"Session Feedback Score"}
                      // yAxisLabel={"Session Count"}
                      // xAxisLabel={"Score"}
                      // dataColors='["--bs-success","--bs-primary"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
              <Card className="mt-3">
                <CardBody>
                  <div id="revenue-chart">
                    <CrossDepBarChart
                      chartData={crossDepartmentSessionStats}
                      // titleText={"Session Feedback Score"}
                      // yAxisLabel={"Session Count"}
                      // xAxisLabel={"Score"}
                      dataColors='["--bs-success","--bs-primary"]'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(DataSummary);
