import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";

import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import ManageMembersTable from "Components/ManageMembersTable";
import withRouter from "Components/Common/withRouter";

//Import Breadcrumb
import Breadcrumbs from "Components/Common/Breadcrumb";

import {
  fetchUsers,
  onAcceptUserRequest,
  onStatusChange,
} from "../../../slices/admin/thunk";

import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import Paginations from "Components/Common/Pagination";

const ManageMembers = (props: any) => {
  //meta title
  document.title = "User List | Mentorgain";

  const selectProperties = createSelector(
    (state: any) => state.Admin,
    (admin) => ({
      users: admin.users,
      loading: admin.loading,
    })
  );
  const { users, loading }: any = useSelector(selectProperties);
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [contact, setContact] = useState<any>();
  const [currentFilter, setCurrentFiler] = useState<any>('PENDING_APPROVAL');
  const [name, setName] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const dispatch = useDispatch<any>();

  const actionLabels: Record<string, string> = {
    ACTIVE: 'Activate',
    DEACTIVE: 'Deactivate',
    BLOCKED: 'Block',
    REJECTED: 'Reject',
  };
  const label = actionType as keyof typeof actionLabels;

  const getStatusColumns = useCallback(() => {
    switch (currentFilter) {
      case 'PENDING_APPROVAL':
        return [{
          header: "Actions",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (cellProps: any) => {
            return (
              <>
                <Link
                  to="#!"
                  className="text-success"
                  onClick={(event: any) => {
                    event.preventDefault();
                    setModal(true);
                    const userData = cellProps.row.original;
                    setContact(userData.id);
                    setActionType('APPROVED');
                  }
                  }
                >
                  <i className="mdi mdi-cogs font-size-20" id="approveTooltip" />
                </Link>
              </>
            )
          }
        }];
      case 'ACTIVE':
        return [{
          header: "Actions",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (cellProps: any) => {
            return (
              <div className="d-flex gap-3">
                <>
                  <Link
                    to="#!"
                    className="text-danger"
                    onClick={(event: any) => {
                      event.preventDefault();
                      setContact(cellProps.row.original.id);
                      setActionType('DEACTIVE');
                      setModal(true);
                    }}
                  >
                    <i className="mdi mdi-account-minus-outline font-size-20" id="deactivateTooltip" />
                  </Link>
                  <UncontrolledTooltip
                    placement="top"
                    target="deactivateTooltip"
                  >
                    Deactivate
                  </UncontrolledTooltip>
                </>
                <>
                  <Link
                    to="#!"
                    className="text-danger"
                    onClick={(event: any) => {
                      event.preventDefault();
                      setActionType('BLOCKED');
                      setContact(cellProps.row.original.id);
                      setModal(true);
                    }}
                  >
                    <i className="mdi mdi-account-cancel-outline font-size-20" id="blockedTooltip" />
                  </Link>
                  <UncontrolledTooltip
                    placement="top"
                    target="blockedTooltip"
                  >
                    Block
                  </UncontrolledTooltip>
                </>
                <>
                  <Link
                    to="#!"
                    className="text-danger"
                    onClick={(event: any) => {
                      event.preventDefault();
                      setContact(cellProps.row.original.id);
                      setActionType('REJECTED');
                      setModal(true);
                    }
                    }
                  >
                    <i className="mdi mdi-account-off-outline font-size-20" id="rejectedTooltip" />
                  </Link>
                  <UncontrolledTooltip
                    placement="top"
                    target="rejectedTooltip"
                  >
                    Reject
                  </UncontrolledTooltip>
                </>
              </div>
            )
          }
        }];
      case 'DEACTIVE':
      case 'BLOCKED':
      case 'REJECTED':
        return [{
          header: "Actions",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (cellProps: any) => {
            return (
              <>
                <Link
                  to="#!"
                  className="text-success"
                  onClick={(event: any) => {
                    event.preventDefault();
                    setContact(cellProps.row.original.id);
                    setActionType('ACTIVE');
                    setModal(true);
                  }
                  }
                >
                  <i className="mdi mdi-check font-size-20" id="activateTooltip" />
                  <UncontrolledTooltip placement="top" target="activateTooltip">
                    Activate
                  </UncontrolledTooltip>
                </Link>
              </>
            )
          }
        }];
      default:
        return [];
    }
  }, [currentFilter]);

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <>
              {!cellProps.row.original.img ? (
                <div style={{ height: '1.5rem', width: '1.5rem' }}>
                  <span className="avatar-title rounded-2">
                    {cellProps.row.index + (currentPage - 1) * perPage + 1}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-2 avatar-xs"
                    src={cellProps.row.original.img}
                    alt="Profile Logo"
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        header: "First Name",
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Last Name",
        accessorKey: "lastName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      ...(currentFilter !== 'PENDING_APPROVAL' ? [
        {
          header: "Role(s)",
          accessorKey: "roles",
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps: any) => {
            return cellProps.row.original.roles.join(', ')
          },
        }
      ] : []),
      ...getStatusColumns(),
    ],
    [getStatusColumns, currentFilter, currentPage, perPage]
  );

  useEffect(() => {
    const fetchParams = {
      type: 'status',
      value: currentFilter,
      name: name,
      perPage: perPage,
      page: currentPage,
    };
    dispatch(fetchUsers(fetchParams));
  }, [dispatch, currentFilter, name, currentPage, perPage]);

  const handleUserRequest = async (requestType: any) => {
    if (requestType) {
      await dispatch(onAcceptUserRequest({ userId: contact, requestType }));
      await dispatch(
        fetchUsers({
          type: 'status',
          name: name,
          value: currentFilter,
          perPage: perPage,
          page: currentPage,
        })
      );
      setModal(false);
    }
  };

  const handleStatusChange = async () => {
    if (actionType) {
      await dispatch(onStatusChange({ userId: contact, status: actionType }));
      await dispatch(
        fetchUsers({
          type: 'status',
          value: currentFilter,
          name: name,
          perPage: perPage,
          page: currentPage,
        })
      );
      setModal(false);
    }
  };

  const handleFilterChange = (type: any, value: any) => {
    setCurrentFiler(value);
    setCurrentPage(1);
  };

  const handleNameChange = (name: string) => {
    setName(name);
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administration" breadcrumbItem="Manage Members" />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card>
                  <CardBody>
                    <ManageMembersTable
                      columns={columns}
                      data={users.users || []}
                      tableClass="table-sm align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={users.totalPages}
                      handleFilterChange={handleFilterChange}
                      handleNameChange={handleNameChange}
                    />
                    <Paginations
                      perPageData={perPage}
                      setPerPage={setPerPage}
                      totalDataCount={users?.total}
                      totalPages={users?.totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={true}
                      paginationClass="pagination pagination-rounded"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Change User Status
            </ModalHeader>
            <ModalBody>
              <p>
                {actionType === 'APPROVED'
                  ? 'Are you sure you want to approve this user?'
                  : `Are you sure you want to ${actionLabels[label]?.toLowerCase() || ''} this user?`}
              </p>
              <div className="text-right d-flex justify-content-end">
                {actionType === 'APPROVED' && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={() => {
                        handleUserRequest('approve');
                      }}
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={() => handleUserRequest('reject')}
                    >
                      Reject
                    </button>
                  </>
                )}
                {actionType !== 'APPROVED' && (
                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={handleStatusChange}
                  >
                    {actionLabels[label]}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={toggle}
                >
                  Close
                </button>
              </div>
            </ModalBody>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ManageMembers);
