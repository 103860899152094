import React, { useState } from 'react';

const SkillsCell = ({ skills }: { skills: string[] }) => {
  const [expanded, setExpanded] = useState(false);

  if (!skills || skills.length === 0) {
    return <p style={{ margin: 0, padding: "10px 0 10px 20px" }}>NA</p>;
  }

  const displaySkills = expanded ? skills : skills.slice(0, 2);
  const hasMoreSkills = skills.length > 2;

  return (
    <div style={{ 
      width: "100%", 
      padding: "10px",
      position: "relative",
      minHeight: "60px"
    }}>
      <ul style={{ margin: 0, paddingLeft: "20px" }}>
        {displaySkills.map((skill, index) => (
          <li key={index} style={{ marginBottom: "5px" }}>{skill}</li>
        ))}
      </ul>
      
      {hasMoreSkills && (
        <div 
          onClick={() => setExpanded(!expanded)} 
          style={{
            textAlign: "center",
            color: "#4a6cf7",
            cursor: "pointer",
            fontWeight: 500,
            marginTop: "5px",
            padding: "2px 0",
            borderTop: "1px dashed #e0e0e0"
          }}
        >
          {expanded ? "Show Less" : `Show ${skills.length - 2} More`}
        </div>
      )}
    </div>
  );
};

export default SkillsCell;