import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

// Auth
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/UserProfile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register";
import ManageMembers from "pages/Administration/ManageMembers";
import AcceptInvite from "pages/Authentication/AcceptInvite";
import InvitedUsers from "pages/Administration/InvitedUsers";
import PendingApproval from "pages/Utility/error/pending_approval";
import UserRejected from "pages/Utility/error/user_rejected";
import UserBlocked from "pages/Utility/error/user_blocked";
import UserDeactive from "pages/Utility/error/user_deactive";
import ResetPassword from "pages/Authentication/ResetPassword";
import MentorMenteeSurvey from "pages/Onboarding/MentorMenteeSurvey";
import UploadProfile from "pages/Onboarding/UploadProfile";
import { MentorSurvey } from "pages/Onboarding/MentorSurvey";
import { MenteeSurvey } from "pages/Onboarding/MenteeSurvey";
import Chat from "pages/Chat/Chat";
import Availability from "pages/Onboarding/Availability";
import MentorList from "pages/Mentor/MentorList";
import MentorMenteeProfile from "pages/Profile";
import Sessions from "pages/Sessions";
import PostSessionFeedback from "../pages/Feedback/PostSessionFeedback";
import PlatformFeedback from "../pages/Feedback/PlatformFeedback";
import { Suggest } from "../pages/Pairing/Suggest";

import { Faq } from "pages/Faq";
import VerifyEmail from "../pages/Authentication/VerifyEmail";
import VerifySecondaryEmail from "pages/Authentication/VerifySecondaryEmail";
import ExistingPairs from "../pages/Pairing/ExistingPairs";
import InviteUsers from "pages/Administration/InviteUsers";
import MenteeDashboard from "pages/Dashboard/MenteeDashboard";
import MentorDashboard from "pages/Dashboard/MentorDashboard";
import AdminDashboard from "pages/Dashboard/AdminDashboard";
import { MentorGuidelines } from "pages/Mentor/MentorGuidelines";
import { MenteeGuidelines } from "pages/Mentee/MenteeGuidelines";
import Notes from "pages/Notes";
import Reports from "pages/Administration/Reports";
import DataSummary from "pages/Administration/DataSummary";

const authProtectedRoutes = [
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/mentee", component: <MenteeDashboard /> },
  { path: "/dashboard/mentor", component: <MentorDashboard /> },
  { path: "/dashboard/admin", component: <AdminDashboard /> },
  // TODO: Change route to something more meaningful??
  { path: "/profile", component: <UserProfile /> },
  { path: "/faq", component: <Faq /> },

  // Mentor
  { path: "/explore-mentors", component: <MentorList type="all"/>},
  { path: "/suggested-mentors", component: <MentorList type="suggested"/>},
  { path: "/suggested-mentees", component: <MentorList type="suggested"/>},
  { path: "/matched-mentors", component: <MentorList type="matched"/>},
  { path: "/mentor/mentor-guidelines", component: <MentorGuidelines /> },

  // Mentor and Mentee profile
  { path: "/profile/:id", component: <MentorMenteeProfile /> },
  { path: "/mentee/mentee-guidelines", component: <MenteeGuidelines /> },

  // Administration
  { path: "/admin-members", component: <ManageMembers /> },
  { path: "/admin-invited-users", component: <InvitedUsers /> },
  { path: "/admin-invite-users", component: <InviteUsers /> },
  { path: "/admin-reports", component: <Reports /> },
  { path: "/admin-data_summary", component: <DataSummary /> },

  // Onboarding
  { path: '/onboarding/upload-profile', component: <UploadProfile /> },
  { path: '/onboarding/set-availability', component: <Availability /> },
  { path: '/onboarding/create', component: <MentorMenteeSurvey /> },
  { path: '/onboarding/create/mentor-form', component: <MentorSurvey /> },
  { path: '/onboarding/create/mentee-form', component: <MenteeSurvey /> },

  // Sessions
  { path: "/sessions", component: <Sessions />},
  { path: "/feedbacks", component: <Sessions />},
  { path: "/sessions/:contactId", component: <Sessions />},
  { path: "/sessions/:sessionId/feedback", component: <PostSessionFeedback />},
  { path: "/sessions/:sessionId/platform-feedback", component: <PlatformFeedback />},

  { path: '/chat', component: <Chat /> },
  { path: '/pair-mentor-mentee', component: <Suggest /> },
  { path: '/existing-pairs', component: <ExistingPairs /> },
  { path: "/logout", component: <Logout /> },
  { path:  "/notes", component: <Notes/>}
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  // { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/verify-email", component: <VerifyEmail /> },
  { path: "/register", component: <SignUp /> },
  { path: "/accept-invite", component: <AcceptInvite /> },
  { path: "error/pending-approval", component: <PendingApproval /> },
  { path: "error/user-rejected", component: <UserRejected /> },
  { path: "error/user-blocked", component: <UserBlocked /> },
  { path: "error/user-deactive", component: <UserDeactive /> },
];

const hybridRoutes = [
  { path: "/verify-secondary-email", component: <VerifySecondaryEmail /> },
]

export { authProtectedRoutes, publicRoutes, hybridRoutes };
