import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartDynamicColor";

const VerticalBarChart = ({ series, categories, titleText, xAxisLabel, yAxisLabel, dataColors }: any) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  

  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: true,
      },
    }, 
    plotOptions: {
      bar: {
        horizontal: false, // ✅ Changed to vertical
      },
    },
    series,
    title: {
        text: titleText,
        align: "left",
        style: {
          fontWeight: "500",
        },
      },
    dataLabels: {
      enabled: true,
    },
    colors: spineareaChartColors,
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      borderColor: "#f1f1f1",
    },
    xaxis: {
      title: {
        text: xAxisLabel,
      },
      categories,
    },
    yaxis: {
      title: {
        text: yAxisLabel,
      },
    },
  };
 
  return <ReactApexChart options={options} series={series} type="bar" height="380"  className="apex-charts" />;
};

export default VerticalBarChart;
 