import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

import { Message, RecentChat } from "./type";

import ChatList from "./ChatList";
import UserChat from "./UserChat";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";

import {getMessages as onGetMessages, setCurrentChatBoxUser} from "../../slices/chats/thunk"

import {APIClient} from "../../helpers/api_helper";

const api = new APIClient();

interface ChatState {
  Chats: {
    messages: Message[];
    loading: boolean;
    chats: RecentChat[];
    currentRoomId?: string;
    chatBoxUsername?: string;
    chatBoxUserOnline?: boolean;
    isMentor: boolean;
  };
}

const Chat: React.FC = () => {
  //meta title
  document.title = "Chats | Mentorgain";

  const dispatch = useDispatch<any>();
  const selectProperties = createSelector(
    (state: ChatState) => state.Chats,
    (chats) => ({
      messages: chats.messages,
      loading: chats.loading,
      chats: chats.chats,
      currentRoomId: chats.currentRoomId,
      chatBoxUsername: chats.chatBoxUsername,
      chatBoxUserOnline: chats.chatBoxUserOnline,
      isMentor: chats.isMentor,
    })
  );

  const {
    messages,
    loading,
    chats,
    currentRoomId,
    chatBoxUsername,
    chatBoxUserOnline,
    isMentor,
  } = useSelector(selectProperties);
  const [chatOpened, setChatOpened] = useState<boolean>(false);

  useEffect(() => {
    if (currentRoomId) {
      dispatch(onGetMessages(currentRoomId));
    }
  }, [dispatch, currentRoomId]);

  const userChatOpen = useCallback(
    (chatUser) => {
      dispatch(
        setCurrentChatBoxUser({
          name: `${chatUser.firstName} ${chatUser.lastName}`,
          roomId: chatUser.id,
          online: chatUser.online,
          isMentor: chatUser.isMentor,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (chats.length >= 1 && !chatOpened && !currentRoomId) {
      userChatOpen(chats[0].user);
      setChatOpened(true);
    }
  }, [dispatch, chats, chatOpened, userChatOpen, currentRoomId]);


  useEffect( () => {
    const intervalId = setInterval(() => {
      api.create("/api/v1/chats/ping", {})
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Mentorgain" breadcrumbItem="Chats" />
          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">
                <ChatList
                  userChatOpen={userChatOpen}
                  currentRoomId={currentRoomId}
                />
                {
                  currentRoomId &&
                  <UserChat
                    chatBoxUsername={chatBoxUsername}
                    chatBoxUserOnline={chatBoxUserOnline}
                    messages={messages}
                    loading={loading}
                    currentRoomId={currentRoomId}
                    isMentor={isMentor}
                  />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};



export default Chat;
