import React from "react";
import "./App.css";
import { authProtectedRoutes, publicRoutes, hybridRoutes } from "./Routes/allRoutes";
import { Route, Routes, Navigate } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import "./assets/scss/theme.scss";
import SimpleLayout from "./Layouts/SimpleLayout";
import "react-toastify/dist/ReactToastify.css";

// Added temporarily for surveyjs
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
//flatpickr
import "flatpickr/dist/themes/material_blue.css";

import { useAuth } from "./Layouts/AuthContext";
import { ToastContainer } from "react-toastify";
import LoadingFullScreen from "./Components/LoadingFullScreen";

function App() {
  const { isLoggedIn, loading } = useAuth();
  const Layout = VerticalLayout;
  if (loading) {
    return <LoadingFullScreen title="Just a moment" subtitle="Loading..." />;
  }

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              isLoggedIn ? (
                <Navigate to="/" replace />
              ) : (
                <SimpleLayout>{route.component}</SimpleLayout>
              )
            }
          />
        ))}
        {hybridRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={<SimpleLayout>{route.component}</SimpleLayout>}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => {
          if (
            route.path.includes("/onboarding/") ||
            route.path.includes("/platform-feedback")
          ) {
            return (
              <Route
                path={route.path}
                key={idx}
                element={
                  isLoggedIn ? (
                    <SimpleLayout>{route.component}</SimpleLayout>
                  ) : (
                    // Redirect to login page if not authenticated
                    <Navigate to="/login" replace />
                  )
                }
              />
            );
          }

          return (
            <Route
              path={route.path}
              key={idx}
              element={
                isLoggedIn ? (
                  <React.Fragment>
                    <Layout>{route.component}</Layout>
                  </React.Fragment>
                ) : (
                  // Redirect to login page if not authenticated
                  <Navigate to="/login" replace />
                )
              }
            />
          );
        })}
      </Routes>
      <ToastContainer position="bottom-right" />
    </React.Fragment>
  );
}

export default App;
