import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
 
const api = new APIClient();

export const getAnalyticsCount = createAsyncThunk(
  "data-summary/getCount",
  async (filterData: any) => {
    try {
      const response = await api.get("/api/v1/data-summary/counts",  {
        params: filterData,
      });

      
      return response;
    } catch (error) {
      console.error("Error fetching analytics count:", error);
      throw error;
    }
  }
);

export const getSessionByDuration = createAsyncThunk(
  "data-summary/getSessionsByDuration",
  async (filterData: any) => {
    try {
      const response = await api.get(
        "/api/v1/data-summary/sessions/by-duration",  {
          params: filterData,
        }
      );
      
      return response?.totalSessions;
    } catch (error) {
      console.error("Error fetching sessions by duration:", error);
      throw error;
    }
  }
);


export const getSessionByFeedbackScore = createAsyncThunk(
  "data-summary/getSessionByFeedbackScore",
  async (filterData: any) => {
    try {
      const response = await api.get(
        "/api/v1/data-summary/sessions/by-feedbackScore",  {
          params: filterData,
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching sessions by feedbackScore:", error);
      throw error;
    }
  }
);

export const getSkillTrends = createAsyncThunk(
  "data-summary/getSkillTrends",
  async (filterData: any) => {
    try {
      const response = await api.get(
        "/api/v1/data-summary/sessions/skill-trends",  {
          params: filterData,
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching sessions by feedbackScore:", error);
      throw error;
    }
  }
);

export const getSessionByDay = createAsyncThunk(
  "data-summary/getSessionsByDay",
  async (filterData: any) => {
    try {
      const response = await api.get("/api/v1/data-summary/sessions/by-multiple",  {
        params: filterData,
      });
      return response;
    } catch (error) {
      console.error("Error fetching sessions by day:", error);
      throw error;
    }
  } 
);

export const getUsersByDomain = createAsyncThunk(
  "data-summary/getUsersByDomain",
  async (filterData: any) => {
    try {
      const response = await api.get("/api/v1/data-summary/sessions/by-domain",  {
        params: filterData,
      });
     
      return response;
    } catch (error) {
      console.error("Error fetching users by domain:", error);
      throw error;
    }
  }
);

export const getEngagementTrend = createAsyncThunk(
  "data-summary/getEngagementTrend",
  async (filterData: any) => {
    try {
      const response = await api.get("/api/v1/data-summary/sessions/eng-data-by-activeUsers",  {
        params: filterData,
      });
     
      return response;
    } catch (error) { 
      console.error("Error fetching engagments Data:", error);
      throw error;
    }
  }
);

export const getUserAnalytics = createAsyncThunk(
  "data-summary/getUsers",
  async (filterData: any) => {
    try {
      const { filterQuery, perPage, page, fromDate, toDate } = filterData;
      filterQuery["perPage"] = perPage;
      filterQuery["page"] = page;
      filterQuery["fromDate"] = fromDate;
      filterQuery["toDate"] = toDate;
      const response = await api.get(`/api/v1/data-summary/users`, filterQuery);
      
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      toast.error("Failed to filter the data. Please try again.");
      throw error;
    }
  }
);


export const getWordFrequency = createAsyncThunk(
  "data-summary/getWordFrequency",
  async (filterData: any) => {
    try {
      const response = await api.get(
        `/api/v1/data-summary/sessions/word-frequency`,{params: filterData}
        
      );
      return response;
    } catch (error) {
      console.error("Error fetching sessions word frequency:", error);
      throw error;
    }
  }
);

export const getUserAnalyticsRating = createAsyncThunk(
  "data-summary/rating",
  async (userType: "mentor" | "mentee") => {
    try {
      const response = await api.get(`/api/v1/data-summary/user/rating`, {
        userType: userType,
      });
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      throw error;
    }
  }
);


export const getUserSessionByMonth = createAsyncThunk(
  "data-summary/sessionByMonth",
  async (userType: "mentor" | "mentee") => {
    try {
      const response = await api.get(
        `/api/v1/data-summary/user/session/by-month`,   
        {
          userType: userType,
          perPage: 3,
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      throw error;
    }
  }
);




export const getCrossDepartmentSessionStats = createAsyncThunk(
  "data-summary/getCrossDepartmentSessionStats",
  async (filterData: any) => {
    try {
      const response = await api.get("/api/v1/data-summary/sessions/cross-department-stats",  {
        params: filterData,
      });
     
      return response;
    } catch (error) {
      console.error("Error fetching users by domain:", error);
      throw error;
    }
  }
);

export const getMenteeAvgScore = createAsyncThunk(
  "data-summary/menteeAvgScore",
  async (filterData: any) => {
    try {
      const response = await api.get(`/api/v1/data-summary/avg-score/mentee`,  {
        params: filterData,
      });
      return response;
    } catch (error) {
      console.error("Error fetching mentee average score:", error);
      throw error;
    }
  }
);

export const getMentorAvgScore = createAsyncThunk(
  "data-summary/mentorAvgScore",
  async (filterData: any) => {
    try {
      const response = await api.get(`/api/v1/data-summary/avg-score/mentor`,  {
        params: filterData,
      });
      return response;
    } catch (error) {
      console.error("Error fetching mentor average score:", error);
      throw error;
    }
  }
);
