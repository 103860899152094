import React, { Fragment, useState } from 'react';
import { 
  useReactTable, 
  getCoreRowModel, 
  getFilteredRowModel,
  getSortedRowModel,
  ColumnFiltersState,
  flexRender 
} from '@tanstack/react-table';
import { Table } from 'reactstrap';

interface OrgSessionsTableProps {
  columns?: any;
  data?: any;
  divClassName?: any;
  tableClass?: any;
  isBordered?: boolean;
  buttonClass: string;
  buttonName: string;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const OrgSessionsTable = ({
  columns,
  data,
  tableClass,
  divClassName,
  isBordered,
}: OrgSessionsTableProps) => {

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const table = useReactTable({
    columns,
    data,
    
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
  } = table;

  return (
    <Fragment>
      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table hover className={tableClass} bordered={isBordered}>
          <colgroup>
            {columns.map((column, index) => (
              <col key={index} width={column.size ? `${column.size}px` : "auto"} />
            ))}
          </colgroup>
          <thead>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th 
                    key={header.id} 
                    colSpan={header.colSpan} 
                    className={`${header.column.columnDef.enableSorting ? "sorting sorting_desc" : ""}`}
                    style={{ 
                      padding: '12px'
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {
                            {
                              asc: '',
                              desc: '',
                            }
                            [header.column.getIsSorted() as string] ?? null}
                        </div>
                      </React.Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td 
                        key={cell.id}
                        style={{
                          verticalAlign: "top",
                          position: "relative"
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default OrgSessionsTable;