

export function convertToMinutes(time) {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

export function hasOverlap(slot1, slot2) {
  const start1 = convertToMinutes(slot1.start);
  const end1 = convertToMinutes(slot1.end);
  const start2 = convertToMinutes(slot2.start);
  const end2 = convertToMinutes(slot2.end);

  return start1 < end2 && start2 < end1;
}

export function checkOverlaps(slots) {
  for (let i = 0; i < slots.length; i++) {
    for (let j = i + 1; j < slots.length; j++) {
      if (hasOverlap(slots[i], slots[j])) {
        return true; // Found an overlap
      }
    }
  }
  return false; // No overlaps found
}

export function hasInvalidSlot(slots) {
  for (const slot of slots) {
    const start = convertToMinutes(slot.start);
    const end = convertToMinutes(slot.end);

    if (end < start) {
      return true; // Found a slot with end time earlier than start time
    }
    if (end === start) {
      return true
    }
  }
  return false; // No invalid slots found
}

export function hasEmptySlot(slots) {
  for (const slot of slots) {
    if (slot.start === '' || slot.end === '') {
      return true; 
    }
  }
  return false;
}

export function formatText(str: string) {
  if (!str) return "";
  return str
    .split("-") 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
    .join(" "); 
};