import React, { useEffect, useState } from "react";
import {  Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "Components/Common/withRouter";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { fetchReport } from "../../../slices/reports/thunk";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import Paginations from "Components/Common/Pagination";
import Select from "react-select";
import ReportsTable from "Components/ReportsTable";
import Flatpickr from "react-flatpickr";

import { downloadCsv } from "../../../slices/reports/thunk";

const Reports = () => {
  document.title = "Reports | Mentorgain";

  const selectProperties = createSelector(
    (state: any) => state.Reports,
    (reports) => ({
      reportData: reports,
      loading: reports.loading,
    })
  );

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date>(new Date()); // ✅ Default to today's date

  const { reportData, loading }: any = useSelector(selectProperties);
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [reportType, setReportType] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const reportTypes: any[] = [
    { label: "Top Mentors", value: "top_mentors" },
    { label: "Top Mentees", value: "top_mentees" },
    { label: "Skill Development Trends", value: "skill_trends" },
    { label: "Active Sessions", value: "active_sessions" },
    { label: "Completed Sessions", value: "completed_sessions" },
    { label: "Cancelled Sessions", value: "cancelled_sessions" },
  ];

  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(d.getDate()).padStart(2, "0"); // Ensure 2-digit day

    return `${year}-${month}-${day}`;
  };
  const formattedFromDate = formatDate(fromDate);
  const formattedToDate = formatDate(toDate);

  useEffect(() => {
    // console.log("Report Type", reportType?.value);
    dispatch(
      fetchReport({
        reportType: reportType?.value,
        perPage: perPage,
        page: currentPage,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      })
    );
  }, [dispatch, currentPage, reportType, perPage, formattedFromDate, formattedToDate]);

  const handleDownloadCsv = async () => {
    setDownloading(true);
    await dispatch(
      downloadCsv({
        reportType: reportType?.value,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      })
    );
    setDownloading(false);
  };


  
const tooltipInfo = {
  mentorAvgScores: "Mentor Score = Session Score + Avg Feedback Score (Max: 10). It reflects both mentor activity and session quality.",
  menteeAvgScores: "Mentee Score = Session Score + Avg Feedback Score (Max: 10). It reflects both mentee activity and session quality.",
  avgScore: "Average feedback score is average of all completed sessions feedback score.",
  sessionScore: "Session Score is calculated as: (Users’s Total Sessions / Organization’s Total Sessions) × 5. " +
"This ensures that a user conducting all sessions within the organization receives the maximum score of 5. " +
"For example: If a user conducts 20 sessions out of 100 total sessions, the Session Score = (20 / 100) × 5 = 1. " +
"If a mentor conducts 50 sessions out of 100, the score would be (50 / 100) × 5 = 2.5.",
};


const modifiedColumns = reportData.columns?.map((col) => {
  return {
    ...col,
    header: () => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{col.title}</span>
        {tooltipInfo[col.accessorKey] && (
          <>
            <i
              className="mdi mdi-information-outline ms-1"
              id={`tooltip-${col.accessorKey}`}
              style={{ cursor: "pointer", fontSize: "16px", color: "#007bff" }}
            />
            <UncontrolledTooltip placement="top" target={`tooltip-${col.accessorKey}`}>
              {tooltipInfo[col.accessorKey]}
            </UncontrolledTooltip>
          </>
        )}
      </div>
    ),
  };
});
 
console.log("Modified Columns:", modifiedColumns);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administration" breadcrumbItem="Reports" />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card>
                  <div className="d-flex flex-wrap mt-4 mx-4">
                    {
                      <div
                        className="col-6 col-md-2"
                        style={{ marginRight: "25px" }}
                      >
                        
                        <Select
                          name="ReportType"
                          placeholder="Report Types"
                          options={reportTypes}
                          onChange={(selectedOptions) => {
                            selectedOptions === null
                              ? setReportType([])
                              : setReportType(selectedOptions);
                            setCurrentPage(1);
                          }}
                          value={reportType}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({ ...base, maxHeight: 300 }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#fff"
                                : provided.backgroundColor,
                              color: state.isSelected ? "#000" : provided.color,
                            }),
                          }}
                        />
                      </div>
                    }

                    <div
                      className="flex w-full justify-end items-center gap-4 mb-4 "
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Flatpickr
                        value={fromDate || undefined}
                        onChange={([date]) => setFromDate(date)}
                        options={{ dateFormat: "Y-m-d" }}
                        className="border p-2 rounded-md"
                        placeholder="Select From date"
                      />

                      <Flatpickr
                        value={toDate}
                        onChange={([date]) => setToDate(date)}
                        options={{ dateFormat: "Y-m-d" }}
                        placeholder="Select To date"
                        className="border p-2 rounded-md"
                      />
                    </div>
                  </div>
                  <CardBody>
                    {reportType.value !== "data_summary" ? (
                      <>
                       
                       {!reportData || !reportData.reportData || reportData.reportData.length > 0 ? (   <div style={{display:"flex", alignContent:"end", justifyContent:"end"}}>
                          <button
                            onClick={handleDownloadCsv}
                            disabled={isDownloading}
                            className="download-btn"
                            
                          >
                            {isDownloading ? (
                              <i className="bx bx-loader bx-spin"></i> // Spinning loader icon
                            ) : (
                              <i className="bx bx-download"></i> // Regular download icon
                            )}
                          </button>
                        </div> ) : (
                      <div >
                       
                      </div> 
                    ) }
                        <ReportsTable
                          columns={modifiedColumns}
                          data={reportData.reportData || []}
                          tableClass="table-sm align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                          buttonClass="btn btn-primary btn-sm addContact-modal mb-2 text-white"
                          buttonName="Invite User"
                          currentPage={currentPage}
                          totalPages={reportData.totalPages}
                          setCurrentPage={setCurrentPage}
                        />
                        <Paginations
                          perPageData={perPage}
                          setPerPage={setPerPage}
                          totalDataCount={reportData.total}
                          totalPages={reportData.totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          isShowingPageLength={true}
                          paginationClass="pagination pagination-rounded"
                        />
                      </>
                    ) : (
                      <div className="p-4 bg-blue-100 rounded-lg">
                        <h2 className="text-lg font-bold">Other Report</h2>
                        <p>
                          This section shows details for other report types.
                        </p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Reports);
