import { Card, CardBody } from "reactstrap";

//Import Images
import profile1 from "assets/images/profile-img.png";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { formatText } from "../../../helpers/functions";

const WelcomeCard = () => {
  const selectProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
    })
  );

  const { user } = useSelector(selectProperties);

  return (
    <Card className="overflow-hidden">
      {/* Welcome Banner Section */}
      <div className="bg-primary-subtle d-flex justify-content-between align-items-end">
        <div className="text-primary p-4">
          <h5 className="text-primary mb-0">Welcome Back!</h5>
        </div>
        <div className="w-25">
          <img src={profile1} alt="" className="img-fluid" />
        </div>
      </div>

      <CardBody className="pt-0 text-center">
        <div className="mb-2">
          <img
            src={user.avatarURL || profile1}
            alt="User Profile"
            className="img-thumbnail rounded-circle"
            style={{ width: "70px", height: "70px" }}
          />
        </div>
        <h5 className="font-size-16">Hello, {user.firstName}</h5>

        <h6 className="text-primary font-weight-bold mt-1">{formatText(user?.title)}</h6>
        
        <div className="mt-2 d-flex justify-content-center">
          <span className="badge bg-primary font-size-10 px-3 py-2 shadow-sm" style={{ maxWidth: "200px", whiteSpace: "wrap", overflow: "hidden" }}>
          {formatText(user?.department)}
          </span>
        </div>
      </CardBody>
    </Card>  );
};

export default WelcomeCard;